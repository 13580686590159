import RiskSideMenu from "../Components/RiskSideMenu";
import {Link, useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import {GetUser, SendDelete, SendGet} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";
import { primaryColor } from "../configuration/colorPalet";

export default function Processus() {

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#FFFFFF'
        },
        mainSectionContainer: {
            marginLeft: 20,
            margin: 20,
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
        },
        mainTitle: {
            fontSize: 34,
            color: primaryColor,
            fontWeight: 400,
            marginLeft: 20
        },
        mainButton: {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            textTransform: 'uppercase',
            borderRadius: 5,
            padding: 4,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none',
            border: 'solid 1px #000000'
        },
        mainButtonIcon: {
            color: '#000000',
            verticalAlign: 'middle',
            fontSize: 20,
            fontWeight: 700
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'row',
            border: 'solid 1px #E2E2E2',
            padding: 20,
            marginBottom: 20,
            borderRadius: 5
        },
        mainContainer: {
            marginTop: 20
        },
        flagIcon: {
            width: 50,
            height: 30,
            verticalAlign: 'middle',
            marginRight: 10
        },
        businessUnitLabel: {
            color: primaryColor
        },
        actionIcon: {
            fontSize: 22,
            fontWeight: 600,
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            padding: 8,
            marginRight: 10,
        }
    }
    const navigate = useNavigate();
    const [processusList, setProcessusList] = useState([]);

    const getProcessus = async () => {
        try {
            const response = await SendGet(`api/v1/processus/all`);
            if (response.data) {

                setProcessusList( response.data );
            }
        } catch (Exception) {
            console.log(Exception);
        }
    };

    const deleteProcessus = async (id) =>  {

        if( window.confirm("Confirmez-vous la suppression ?") )
        {
            try
            {
                await SendDelete(`api/v1/processus/${id}`);
                await getProcessus();
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getProcessus();
    }, []);


    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                        <span onClick={() => navigate("/processus/create")} style={styles.mainButton} className="btn-primary-custom" >
                            <span className="material-symbols-outlined" style={styles.mainButtonIcon}>add</span>
                        </span>
                    </div>
                    <div style={styles.mainContainer}>
                        {
                            processusList && processusList.map(item => {
                                return (
                                    <div key={item.id} style={styles.cardContainer}>
                                        <div style={{ flex: 1 }}>
                                            <span style={styles.businessUnitLabel}>{item.label}</span>
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <Link to={`/processus/edit/${item.id}`} style={{ ...styles.actionIconContainer, borderColor: '#094392', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: '#094392' }}>edit</span></Link>
                                            <span onClick={() => deleteProcessus(item.id)} style={{ ...styles.actionIconContainer, cursor: 'pointer', borderColor: 'red', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: 'red' }}>delete</span></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
