import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {useEffect, useState} from "react";
import MappingContainer from "../Components/MappingContainer";
import { SendGet, isEmpty } from "../State/Helper";
import NotificationBadge from "../Components/NotificationBadge";

export default function Dashboard() {

    const [showSideMenu, setShowMenu] = useState(true);
    const [processusList, setProcessusList] = useState([]);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#FFFFFF'
        },
        menuContainer: {
        },
        contentContainer: {
            flex: 5,
            padding: 20
        }
    }

    // Get list of cartographies
    async function getProcessusList() {
        
        const response = await SendGet(`api/v1/processus/all`);

        if( !isEmpty(response.data) )
        {
            setProcessusList(response.data);
        }
        else
        {
            setProcessusList([]);
        }
    }

    useEffect(() => {
        getProcessusList();
    }, [])

    return (
        <>
            <RiskTopMenu setShowSideMenu={() => setShowMenu(!showSideMenu)} showSideMenu={showSideMenu} />
            <div style={styles.container}>
                <div style={styles.menuContainer}>
                    <RiskSideMenu showSideMenu={showSideMenu} selectedTab="list" />
                </div>
                <div style={styles.contentContainer}>
                    <NotificationBadge />
                    {
                        processusList.length > 0 && processusList.map(item => {
                            return (
                                <MappingContainer processus={item} key={item.id} />
                            )
                        })
                    }

                </div>
            </div>
        </>
    );
}
