import RiskSideMenu from "../Components/RiskSideMenu";
import Error from "../Components/Error";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getConnectedUser, isEmpty, SendGet, SendPost, SendPut} from "../State/Helper";
import RiskLeveling from "../Components/RiskLeveling";
import ProbabilityLevel from "../Components/ProbabilityLevel";
import {calculateCriticite, getExposition} from "../configuration/riskUtils";
import RiskTopMenu from "../Components/RiskTopMenu";
import MaitriseLevel from "../Components/MaitriseLevel";

export default function CreateAssessment() {

    const { riskId, id } = useParams();

    const [isEditMode, setIsEditMode] = useState(false);
    const [version, setVersion] = useState('');
    const [ba, setBa] = useState('');
    const [assessmentDate, setAssessmentDate] = useState('');
    const [irreleventRisk, setIrreleventRisk] = useState('');
    const [occurrence, setOccurrence] = useState(1);
    const [comment, setComment] = useState('');
    const [error, setError] = useState(null);
    const [assessments, setAssessments] = useState([]);
    const [risk, setRisk] = useState(null);
    const [showHelp, setShowHelp] = useState(0);

    const [criminalImpact, setCriminalImpact] = useState(0);
    const [financialImpact, setFinancialImpact] = useState(0);
    const [imageImpact, setImageImpact] = useState(0);
    const [performanceImpact, setPerformanceImpact] = useState(0);
    const [satisfactionImpact, setSatisfactionImpact] = useState(0);
    const [gravityImpact, setGravityImpact] = useState(1);
    const [criticite, setCriticite] = useState(1);

    const [checkFinancier, setCheckFinancier] = useState(false);
    const [checkJuridique, setCheckJuridique] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [checkSatisfaction, setCheckSatisfaction] = useState(false);
    const [checkPerformance, setCheckPerformance] = useState(false);
    const [exposition, setExposition] = useState(1);

    const [maitrise, setMaitrise] = useState(1);

    const navigate = useNavigate();
    const connectedUser = getConnectedUser();

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1,
            backgroundColor: '#FFFFFF',
            padding: 10
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        reaonlyInputField: {
            fontSize: 14,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#e5e5e5'
        },
        selectField: {
            fontSize: 14,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            height: 35,
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        column: {
            flex: 2
        },
        checkboxStyle: {
            width: 18,
            height: 30,
            verticalAlign: "middle",
            marginRight: 5
        }
    }

    function generateData() {

        setFinancialImpact( checkFinancier ? 1 : 0 );
        setCriminalImpact( checkJuridique ? 1 : 0 );
        setImageImpact( checkImage ? 1 : 0 );
        setPerformanceImpact( checkPerformance ? 1 : 0 );
        setSatisfactionImpact( checkSatisfaction ? 1 : 0 );

        let critictiy = calculateCriticite( Number(gravityImpact), Number(occurrence));
        let exposition = getExposition(critictiy, maitrise);

        setCriticite( critictiy );
        setExposition(exposition);
    }

    useEffect(() => {
        generateData();
    }, [ checkFinancier, checkJuridique, checkImage, checkPerformance, checkSatisfaction, gravityImpact, occurrence, maitrise ]);

    // Check if opened in edit mode
    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/assessments/${id}`);

                if(response.data)
                {
                    setVersion(response.data.version);
                    setAssessmentDate(response.data.assesementDate);
                    setIrreleventRisk(response.data.irreleventRisk);
                    setCheckJuridique(response.data.criminalImpact);
                    setCheckFinancier(response.data.financialImpact);
                    setCheckImage(response.data.imageImpact);
                    setCheckPerformance(response.data.performanceImpact);
                    setCheckSatisfaction(response.data.satisfactionImpact);
                    setGravityImpact(response.data.gravityImpact);
                    setOccurrence(response.data.occurrence);
                    setComment(response.data.frComment);
                    setMaitrise(response.data.maitrise);
                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during the getting of the Assessment.`));
    }, []);

    async function getAssessments()
    {
        // Get all assessments for that specific risk
        const assessmentsResponse = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessmentsResponse.data) )
        {
            setAssessments(assessmentsResponse.data);
        }
    }

    // Get the assessments list onload
    useEffect(() => {
        async function fetch() {
            await getRisk();
            await getAssessments();
        }
        fetch();
    }, []);

    // Get risk details
    async function getRisk() {
        const response = await SendGet(`api/v1/risks/${riskId}`);
        if(response.data !== undefined)
        {
            setRisk(response.data);
        }
    }

    // Update risk reevaluate state to 0
    async function updateRisk() {
        await SendPut(`api/v1/risks/${riskId}`, {
            ...risk,
            reevaluate: 0
        });
    }

    // Create new assessment
    const createAssessment = async () => {

        // Check for empty fields
        if ( isEmpty(gravityImpact) || isEmpty(occurrence) || isEmpty(criticite) || isEmpty(maitrise) || isEmpty(exposition) || ( checkFinancier === false && checkImage === false && checkJuridique === false && checkSatisfaction === false && checkPerformance === false ) )
        {
            setError("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        // Find the current active version of the assessment
        let objectWithMaxVersion = 0;

        if( assessments.length > 0 )
        {
            objectWithMaxVersion = assessments.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);
        }

        // Send the assessment to the backend to be saved
        SendPost(`api/v1/assessments`, {
           version: parseInt(objectWithMaxVersion.version) ? parseInt(objectWithMaxVersion.version) + 1 : parseInt(assessments.length) + 1,
           baId: null,
           creatorId: connectedUser.id,
           riskId: riskId,
           assesementDate: Date.now(),
           irreleventRisk: irreleventRisk,
           impact: null,
           criminalImpact: criminalImpact,
           financialImpact: financialImpact,
           imageImpact: imageImpact,
           performanceImpact: performanceImpact,
           satisfactionImpact: satisfactionImpact,
           gravityImpact: Number(gravityImpact),
           occurrence: Number(occurrence),
           frComment: comment,
           maitrise: Number(maitrise),
           criticite: Number(criticite),
           exposition: Number(exposition),
           credate: Date.now()

        })
        .then(async () => {
            await updateRisk();
            navigate(`/risks/detail/${riskId}`);
        })
        .catch(error => {
            setError('Une erreur s\'est produite lors de l\'action.', error);
        });

        // Clean the fields
        setCriminalImpact(null);
        setFinancialImpact(null);
        setImageImpact(null);
        setSatisfactionImpact(null);
        setPerformanceImpact(null);
        setGravityImpact(null);
        setOccurrence(null);
        setComment("");
        setMaitrise(null);
    }

    return (
        <div>
            <RiskTopMenu />
            <Error errorMessage={error} isVisible={!isEmpty(error)} handleClose={() => setError(null)}>{error}</Error>
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                    </div>
                    <div style={styles.formContainer}>

                        {id && <span style={{color: '#024194'}}><input type="checkbox"/> <span
                            className="material-symbols-outlined" style={{verticalAlign: 'middle'}}>block</span> Irrelevant risk</span>}

                        {showHelp === 1 && <RiskLeveling />}

                        {showHelp === 3 && <MaitriseLevel/>}

                        <div style={{...styles.row, marginTop: 20}}>

                            <div style={{...styles.inputRow, ...styles.column}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined"
                                                                    style={styles.iconStyle}>label_important</span> Qualification des impacts <span
                                    style={styles.requiredLabel}>*</span></span>
                                <div style={{flex: 1, display: 'flex', marginTop: 15}}>
                                    <span style={{flex: 1}}>
                                        <input type="checkbox" style={styles.checkboxStyle}
                                               onChange={e => setCheckFinancier(e.target.checked)}
                                               checked={checkFinancier}/>
                                        <label>Financier</label>
                                    </span>
                                    <span style={{flex: 1}}>
                                        <input type="checkbox" style={styles.checkboxStyle}
                                               onChange={e => setCheckPerformance(e.target.checked)}
                                               checked={checkPerformance}/>
                                        <label>Performance</label>
                                    </span>
                                    <span style={{flex: 1}}>
                                        <input type="checkbox" style={styles.checkboxStyle}
                                               onChange={e => setCheckImage(e.target.checked)} checked={checkImage}/>
                                        <label>Image</label>
                                    </span>
                                    <span style={{flex: 1}}>
                                        <input type="checkbox" style={styles.checkboxStyle}
                                               onChange={e => setCheckSatisfaction(e.target.checked)}
                                               checked={checkSatisfaction}/>
                                        <label>Satisfaction</label>
                                    </span>
                                    <span style={{flex: 1}}>
                                        <input type="checkbox" style={styles.checkboxStyle}
                                               onChange={e => setCheckJuridique(e.target.checked)}
                                               checked={checkJuridique}/>
                                        <label>Judiciaire</label>
                                    </span>
                                </div>
                            </div>

                            <div style={{...styles.inputRow, ...styles.column}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined"
                                                                    style={styles.iconStyle}>label_important</span> Gravité des Impacts <span
                                    style={styles.requiredLabel}>*</span></span>
                                <select style={styles.selectField} onFocus={() => setShowHelp(1)}
                                        defaultValue={gravityImpact} onChange={e => setGravityImpact(e.target.value)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={4}>5</option>
                                </select>
                            </div>

                        </div>

                        <div style={{...styles.row}}>
                            <div style={{...styles.inputRow, flex: 3}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined"
                                                                    style={styles.iconStyle}>label_important</span> Probabilité d'occurrence <span
                                    style={styles.requiredLabel}>*</span></span>
                                <select style={styles.selectField} onFocus={() => setShowHelp(1)}
                                        defaultValue={occurrence} onChange={e => setOccurrence(e.target.value)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={4}>5</option>
                                </select>
                            </div>
                            <div style={{...styles.inputRow, flex: 3}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined"
                                                                    style={styles.iconStyle}>label_important</span> Criticité</span>
                                <input type="text" value={criticite} style={styles.reaonlyInputField} readOnly />
                            </div>
                        </div>

                        <div style={{...styles.row}}>

                            <div style={{...styles.inputRow, ...styles.column}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined"
                                                                    style={styles.iconStyle}>label_important</span> Niveau de maîtrise <span
                                    style={styles.requiredLabel}>*</span></span>
                                <select style={styles.selectField} onFocus={() => setShowHelp(3)}
                                        defaultValue={maitrise} onChange={e => setMaitrise(e.target.value)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                </select>
                            </div>
                            <div style={{...styles.inputRow, flex: 2}}>
                                <span style={styles.colLabel}><span className="material-symbols-outlined"
                                                                    style={styles.iconStyle}>label_important</span> Exposition</span>
                                <input type="text" value={exposition} style={styles.reaonlyInputField} readOnly/>
                            </div>
                        </div>

                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined"
                                                                style={styles.iconStyle}>label_important</span> Commentaire</span>
                            <textarea style={styles.inputField} value={comment}
                                      onChange={e => setComment(e.target.value)}></textarea>
                        </div>

                        <div style={{textAlign: 'center', margin: 20}}>
                            <span onClick={() => navigate(`/risks/detail/${riskId}`)} style={styles.cancelBtn}><span
                                className="material-symbols-outlined"
                                style={styles.iconStyle}>close</span> Fermer</span>
                            {!isEditMode && <span onClick={() => createAssessment()} style={styles.createBtn}><span
                                className="material-symbols-outlined"
                                style={styles.iconStyle}>check</span> Créer</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
