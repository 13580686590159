import ExcelJS from 'exceljs';
import {getColorHexCode, isEmpty, SendGet} from "../State/Helper";
import { useEffect, useState } from "react";
import { useAuth } from "../State/useAuth";

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        width: '600px',
        height: '400px',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#000',
    },
    primaryLabel: {
        fontSize: '28px',
        color: '#000000',
        marginBottom: '20px',
    },
    primaryButton: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        fontSize: '13px',
        fontWeight: 600,
        padding: '10px 20px',
        textTransform: 'uppercase',
        border: 'solid 1px #000000',
        cursor: 'pointer',
    },
    iconStyle: {
        fontSize: '20px',
        fontWeight: 500,
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
};

export default function ExportPopUp({ isOpen, handleClose }) {

    const [riskList, setRiskList] = useState([]);
    const { processus, entite, objectif, searchKeyword, rankFilter } = useAuth();

    const hexToArgb = (hex) => {
        if (hex.startsWith('#')) {
            hex = hex.slice(1); // Remove the leading '#'
        }
        if (hex.length === 6) {
            return `FF${hex}`; // Add 'FF' for full opacity
        }
        throw new Error('Invalid hex color code');
    };

    // Function to export the data to an excel file
    const handleExport = async () => {

        const filteredRisks = [];

        for (const risk of riskList) {
            try {
                // Determine if API calls are necessary or default to empty values
                const processusPromise = risk.processusId !== '*' 
                    ? SendGet(`api/v1/processus/${risk.processusId}`) 
                    : Promise.resolve({ data: { label: '' } });
                const objectifPromise = risk.objectifId !== '*' 
                    ? SendGet(`api/v1/objectif/${risk.objectifId}`) 
                    : Promise.resolve({ data: { label: '' } });
                const entitePromise = risk.entiteId !== '*' 
                    ? SendGet(`api/v1/entite/${risk.entiteId}`) 
                    : Promise.resolve({ data: { label: '' } });
        
                // Await the resolved promises
                const [processus, objectif, entite] = await Promise.all([
                    processusPromise,
                    objectifPromise,
                    entitePromise
                ]);
        
                // Add risk data to the filtered list
                filteredRisks.push({
                    "RISK_ID": Number(risk.id) || 0,
                    "PROCESSUS": String(processus.data.label || ''),
                    "ENTITE": String(entite.data.label || ''),
                    "OBJECTIF": String(objectif.data.label || ''),
                    "RISQUE": String(risk.frTitle || ''),
                    "OCCURRENCE": risk.calcs ? Number(risk.calcs.occurrence) : 0,
                    "CRITICITE": risk.calcs ? Number(risk.calcs.criticite) : 0,
                    "EXPOSITION": risk.calcs ? Number(risk.calcs.exposition) : 0,
                    "MAITRISE": risk.calcs ? Number(risk.calcs.maitrise) : 0
                });
            } catch (error) {
                console.error(`Error fetching data for risk ID ${risk.id}:`, error);
            }
        }

        if (filteredRisks.length === 0) {
            console.error('No data to export');
            return;
        }

        // Create a new workbook and a worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // 1. First Row with "R.A.R&O" text and grey background
        const titleRow = worksheet.addRow(['R.A.R&O']);
        titleRow.font = { bold: false, size: 12 }; // Normal font size
        titleRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'F2F2F2F2'
            }
        };
        worksheet.mergeCells("A1:I1"); // Merge across 9 columns
        titleRow.getCell(1).alignment = { horizontal: 'left' }; // Align text to left

        // 2. Skip a row (row 2 is empty)
        worksheet.addRow([]); // Adding an empty row for spacing

        // 3. Second Row: Rectangle header with "CMGP" centered
        const headerRow = worksheet.addRow(['Cartographie des risques']);
        headerRow.font = { bold: true, size: 14 }; // Set font size and bold
        worksheet.mergeCells("A3:I3"); // Merge across 9 columns
        headerRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' }; // Center text in the merged cell
        headerRow.getCell(1).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };

        const logoPngBuffer = await fetch('./favoris.png').then(res => res.arrayBuffer());
        
        const logoId = workbook.addImage({
            buffer: logoPngBuffer,
            extension: 'png',
        });
        
        const imageWidth = 100;
        const imageHeight = 40;
        
        const rowHeight = 40;
        worksheet.getRow(3).height = rowHeight;
        
        const rowHeightInPixels = rowHeight * 1.33;
        const verticalOffsetPixels = (rowHeightInPixels - imageHeight) / 2;
        const verticalOffset = verticalOffsetPixels / 72;
        
        worksheet.addImage(logoId, {
            tl: { col: 0, row: 2 + verticalOffset },
            ext: { width: imageWidth, height: imageHeight },
        });

        // 4. Skip a row (row 4 is empty)
        worksheet.addRow([]); // Adding an empty row for spacing between "CMGP" and date

        // 5. Third Row: Dynamic date text
        const currentDate = new Date();
        const formattedDate = `Date de mise à jour : ${currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}`;
        const dateRow = worksheet.addRow([formattedDate]);
        dateRow.font = { bold: false, size: 12 }; // Normal font size
        dateRow.getCell(1).alignment = { horizontal: 'left' }; // Align left

        // 6. Skip a row (row 6 is empty)
        worksheet.addRow([]); // Adding an empty row for spacing

        // 7. Add the rest of the data with borders
        const dataHeaderRow = worksheet.addRow(Object.keys(filteredRisks[0]));
        dataHeaderRow.font = { bold: true };
        dataHeaderRow.eachCell((cell) => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        filteredRisks.forEach(risk => {
            const dataRow = worksheet.addRow(Object.values(risk));
            dataRow.eachCell((cell, colNumber) => {
                if (colNumber === 7) { 

                    const criticite = risk.CRITICITE;
                    const backgroundColor = getColorHexCode(criticite);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: hexToArgb(backgroundColor)
                        }
                    };
                }

                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        // Save the workbook to a Blob
        try
        {
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = 'risks_list.xlsx'; // File name
            document.body.appendChild(a);
            a.click(); // Trigger the download
            document.body.removeChild(a); // Clean up
            window.URL.revokeObjectURL(url); // Release the URL
        } catch (error) {
            console.error('Error writing the Excel file:', error);
        }
    };

    async function getRiskList() {

        let response = await SendGet(`api/v1/risks/currentuser`);

        if ( !isEmpty(response.data) ) {

            let parsedProcessus = processus.value !== null ? parseInt(processus.value) : null;
            let parsedEntite = entite.value !== null ? parseInt(entite.value) : null;
            let parsedObjectif = objectif.value !== null ? parseInt(objectif.value) : null;
            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let risks = response.data.filter(item =>
                (parsedProcessus === null || parseInt(item.processusId) === parsedProcessus) &&
                (parsedEntite === null || parseInt(item.entiteId) === parsedEntite) &&
                (parsedObjectif === null || parseInt(item.objectifId) === parsedObjectif) &&
                (parseSearchKeyword === null || item.enTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase())) &&
                (parseSearchKeyword === null || item.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()))
            );

            let assessedRisks = [];

            await Promise.all(risks.map(async risk => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            // Remove the none evaluated ones
            let nonEvaluatedRisks = assessedRisks.filter(item => isEmpty(item.calcs));

            let evaluatedRisks = assessedRisks.filter(item => !isEmpty(item.calcs));

            // Sort the array by recently created
            evaluatedRisks = evaluatedRisks.sort((a, b) => b.credate - a.credate);

            assessedRisks = nonEvaluatedRisks.concat(evaluatedRisks);

            // Filter by the risk rank
            if( rankFilter !== null && rankFilter !== "not_evaluated" )
            {
                const filtered = [];

                evaluatedRisks.map(item => {

                    if( Number(rankFilter) === 1 )
                    {
                        if( Number(item.calcs.criticite) === 1 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 2 )
                    {
                        if( Number(item.calcs.criticite) === 2 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 3 )
                    {
                        if( Number(item.calcs.criticite) === 3 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 4 )
                    {
                        if( Number(item.calcs.criticite) === 4 )
                        {
                            filtered.push(item);
                        }
                    }

                });

                setRiskList(filtered);
            }
            else if( rankFilter === null )
            {
                setRiskList(assessedRisks);
            }
            else if( rankFilter === "not_evaluated" )
            {
                setRiskList(nonEvaluatedRisks);
            }
        }
        else
        {
            setRiskList([]);
        }
    }

    async function getAssessment(riskId) {
        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);
        if (!isEmpty(assessments)) {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        } else {
            return null;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getRiskList();
            } catch (error) {
                console.error(error);
            }
        };
        fetchData().catch(e => console.log(`Error occurred: `, e));
    }, [processus, entite, objectif, searchKeyword, rankFilter]);

    if (!isOpen) return null; // Return null if not open

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <button style={styles.closeButton} onClick={handleClose}>&times;</button>
                <span style={styles.primaryLabel}>Export the current processus</span>
                <div style={styles.buttonContainer}>
                    <button style={styles.primaryButton} className="btn-primary-custom" onClick={handleExport}>
                        <span className="material-symbols-outlined" style={styles.iconStyle}>exit_to_app</span> Export
                    </button>
                </div>
            </div>
        </div>
    );
}