import React from "react";

export default function RiskExpositionChart() {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: 800,
            margin: '0px auto',
            marginTop: 30
        },
        table: {
            borderCollapse: 'collapse'
        },
        column: {
            width: 114,
            textAlign: 'center',
            height: 40
        }
    }

    return (
        <div style={styles.container}>
            <table style={styles.table} border={1}>
                <tbody>
                <tr>
                    <td style={styles.column} colSpan={2}></td>
                    <td style={styles.column} colSpan={4}>Niveau de criticité</td>
                </tr>
                <tr>
                    <td style={styles.column} colSpan={2}></td>
                    <td style={styles.column}>1 - Criticité Très faible</td>
                    <td style={styles.column}>2 - Criticité Plutot faible</td>
                    <td style={styles.column}>3 - Criticité Plutot élveée</td>
                    <td style={styles.column}>4 - Criticité Très élevée</td>
                </tr>
                <tr>
                    <td style={{...styles.column, transform: 'rotate(90deg)'}} rowSpan={4}>Niveau de maitrise</td>
                    <td style={styles.column}>4 - Risque tout à fait maitrisé</td>
                    <td style={{...styles.column, backgroundColor: '#05AF50'}} colSpan={2} rowSpan={2}></td>
                    <td style={{...styles.column, backgroundColor: '#FEBF00'}} colSpan={2} rowSpan={2}>4</td>
                </tr>
                <tr>
                    <td style={styles.column}>3 - Risque plutot maitrisé</td>
                </tr>
                <tr>
                    <td style={styles.column}>2 - Risque plutot non maitrisé</td>
                    <td style={{...styles.column, backgroundColor: '#FFFF00'}} colSpan={2} rowSpan={2}>3</td>
                    <td style={{...styles.column, backgroundColor: '#FF0000'}} colSpan={2} rowSpan={2}>6</td>
                </tr>
                <tr>
                    <td style={styles.column}>1 - Risque pas du tout maitrisé</td>
                </tr>
                </tbody>
            </table>

            <table style={{marginTop: 20}}>
                <tbody>
                <tr>
                    <td style={{width: 200, backgroundColor: '#FF0000'}}></td>
                    <td>Zone "Traitement prioritaire"</td>
                </tr>
                <tr>
                    <td style={{width: 200, backgroundColor: '#FEBF00'}}></td>
                    <td>Zone "Vigilance"</td>
                </tr>
                <tr>
                    <td style={{width: 200, backgroundColor: '#FFFF00'}}></td>
                    <td>Zone "Veille"</td>
                </tr>
                <tr>
                    <td style={{width: 200, backgroundColor: '#05AF50'}}></td>
                    <td>Zone "Maitrise" ou "Survinvetissement ?"</td>
                </tr>
                </tbody>
            </table>

        </div>
    );

}