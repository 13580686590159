import RiskSelect from "./RiskSelect";
import logoImage from "../assets/images/logo.svg";
import {useAuth} from "../State/useAuth";
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import MinMenuItem from "./MinMenuItem";
import {getEntiteList, getObjectifList, getProcessusList} from "../configuration/riskUtils";

export default function RiskTopMenu() {

    const [showProfilMenu, setShowProfilMenu] = useState(false);
    const [listProcessus, setListProcessus] = useState([]);
    const [listEntite, setListEntite] = useState([]);
    const [listObjectif, setListObjectif] = useState([]);
    const navigate = useNavigate();
    const { expandSideMenu, setExpandSideMenu, logout, setActiveMenu, setTabSideMenu, processus, setProcessus, entite, setEntite, objectif, setObjectif } = useAuth();

    const getListProcessus = async () => {
        const data = await getProcessusList();
        setListProcessus( data );
    }

    const getListEntite = async () => {
        const data = await getEntiteList();
        setListEntite( data );
    }

    const getListObjectif = async () => {
        const data = await getObjectifList();
        setListObjectif( data );
    }

    useEffect(() => {
        async function fetch() {
            await getListProcessus();
            await getListEntite();
            await getListObjectif();
        }
        fetch().catch(e => console.log(`Error occurred: `, e));
    }, [processus, entite, objectif]);

    const handleProcessusChange = async (selectedOption) => {
        setProcessus(selectedOption);
        setEntite({ value: null, label: "Tout" });
        setObjectif({ value: null, label: "Tout" });
        
        await localStorage.setItem( 'entite', `{"value":null,"label":"Tout"}` );
        await localStorage.setItem( 'objectif', `{"value":null,"label":"Tout"}` );
    }

    const handleEntiteChange = async (selectedOption) => {
        
        setEntite(selectedOption);
        setObjectif({ value: null, label: "Tout" });
        
        await localStorage.setItem( 'objectif', `{"value":null,"label":"Tout"}` );
    }

    const handleObjectifChange = (selectedOption) => {
        setObjectif(selectedOption);
    }

    const styles = {
        container: {
            display: 'flex',
            flex: 1,
            padding: 20,
            backgroundColor: '#FFFFFF'
        },
        selectContainer: {
            marginRight: 10,
            textAlign: 'center',
            flex: 7.0,
            height: 10,
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: -10,
            marginRight: 20
        },
        selectLabel: {
            fontSize: 12,
            fontWeight: 700,
            color: '#023F96'
        },
        logoStyle: {
            width: 100,
            marginRight: 20,
            paddingTop: 5,
            cursor: 'pointer'
        },
        iconStyle: {
            fontSize: 15,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#023F96'
        },
        buttonIconStyle: {
            fontSize: 22,
            verticalAlign: 'middle',
            color: '#FFFFFF'
        },
        userPanelContainer: {
            display: 'flex',
            flexDirection: 'row',
            margin: 20,
            marginTop: 40,
            width: 140,
            flex: 1
        },
        panelButton: {
            padding:5,
            backgroundColor: '#62CFFC',
            borderRadius: 8,
            border: 'none',
            marginLeft: 5,
            cursor: 'pointer',
            width: 20,
            height: 20,
            justifyContent: 'center',
            alignItems: 'middle'
        },
        menuButton: {
            marginTop: 15,
            marginLeft: 0,
            border: 'none',
            borderRadius: 5,
            height: 25,
            cursor: 'pointer',
            backgroundColor: '#62CFFC',
            textAlign: 'center',
            width: 33
        },
        selectStyle: {
            fontSize: 12,
            borderRadius: 20,
            textAlign: 'left',
            fontWeight: 700,
            backgroundColor: '#FFFFFF',
            color: '#000000',
            minWidth: 130
        },
        inputField: {
            fontSize: 14,
            lineHeight: 2,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        profilePicture: {
            width: 30,
            marginLeft: 20,
            borderRadius: 15,
            cursor: 'pointer'
        },
        profilContainer: {
            display: 'relative'
        },
        profilMenu: {
            position: 'absolute',
            width: 200,
            right: 20,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1000,
            backgroundColor: '#FFFFFF',
            boxShadow: '2px 2px 2px lightgrey',
        },
        profilMenuItem: {
            margin: 0,
            padding: 10,
            cursor: 'pointer'
        },
        profileMenuItemIcon: {
            fontSize: 20,
            verticalAlign: 'middle'
        },
        profileMenuItemText: {
            fontSize: 14
        }
    }

    const setMenuState = () => {

        if( expandSideMenu === null )
        {
            setExpandSideMenu("user");
        }
        else
        {
            setExpandSideMenu(null);
        }
    }

    return (
        <div>
            
            <div style={styles.container}>
                <div style={{ marginLeft: -10, marginTop: -10, marginRight: 28 }}>
                    <MinMenuItem active={true} iconName="menu" label="" onClick={() => setMenuState()} />
                </div>
                <div style={styles.logoContainer}>
                    <img src={logoImage} style={styles.logoStyle} alt="logo" onClick={() => { navigate("/grid"); setActiveMenu("Grid"); setTabSideMenu("user"); }} />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect data={listProcessus}  style={styles.selectStyle} value={processus} onChange={handleProcessusChange} itemName="processus" iconName="linked_services" />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect data={listEntite} style={styles.selectStyle} value={entite} onChange={handleEntiteChange} itemName="entite" iconName="fork_left" />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect useIcon={true} data={listObjectif} style={styles.selectStyle} value={objectif} onChange={handleObjectifChange} itemName="objectif" iconName="flag" />
                </div>
                <div style={styles.profilContainer}>
                    <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png"
                         style={styles.profilePicture}
                         onClick={() => setShowProfilMenu(!showProfilMenu)}
                         alt=""
                    />
                    {
                        showProfilMenu &&
                        <div style={styles.profilMenu}>
                            <div style={styles.profilMenuItem} className="btn-primary-custom" onClick={() => navigate('/users/profile')}>
                                <span style={styles.profileMenuItemIcon} className="material-symbols-outlined material-symbols-filled">person</span> <span style={styles.profileMenuItemText}>{JSON.parse(localStorage.getItem('user')).firstName} {JSON.parse(localStorage.getItem('user')).lastName}</span>
                            </div>
                            <div style={styles.profilMenuItem} className="btn-primary-custom" onClick={() => navigate('/notifications')}>
                                <span style={styles.profileMenuItemIcon} className="material-symbols-outlined material-symbols-filled">notifications</span> <span style={styles.profileMenuItemText}>Notifications</span>
                            </div>
                            <div style={styles.profilMenuItem} className="btn-primary-custom" onClick={logout}>
                                <span style={styles.profileMenuItemIcon} className="material-symbols-outlined material-symbols-filled">logout</span> <span style={styles.profileMenuItemText}>Déconnexion</span>
                            </div>
                        </div>
                    }
                </div>
            </div>    

        </div>
    );
}
