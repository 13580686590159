import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../State/useAuth";
import {getConnectedUser, SendPost} from "../State/Helper";
import MinMenuItem from "./MinMenuItem";
import FullMenuItem from "./FullMenuItem";
import SearchBox from "./SearchBox";
import ExportPopUp from "../screens/Export";

export default function RiskSideMenu() {

    const navigate = useNavigate();
    const { setActiveMenu, expandSideMenu, tabSideMenu, setTabSideMenu, settings } = useAuth();
    const connectedUser = getConnectedUser();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const styles = {
        container: {
            backgroundColor: '#FFFFFF',
            width: 400,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            boxShadow: '2px 2px 4px 0 rgba(103, 208, 250, 0.5)'
        },
        iconStyle: {
            fontSize: 50,
            margin: 8,
            color: '#A9CDFF',
            cursor: 'pointer'
        },
        mainContainer: {
            textAlign: 'center',
            paddingTop: 20,
        },
        iconsContainer: {
            border: 'solid 5px #CADEF8',
            borderRadius: 50,
            marginLeft: 40,
            marginRight: 40
        },
        textLabel: {
            color: '#0258BD',
            fontSize: 14,
            fontWeight: 700
        },
        labelContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            width: 200,
            margin: 'auto',
            marginTop: 10
        },
        textLabelMenu: {
            flex: 1,
            fontSize: 12,
            fontWeight: 700,
            cursor: 'pointer'
        },
        seperator: {
            display: 'flex',
            flex: 1,
            height: 1,
            backgroundColor: '#D9E7FB',
            marginTop: 20
        },
        cardsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        cardContainer: {
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            margin: 20,
            textAlign: 'center',
            borderRadius: '20px 0px 20px 20px',
            paddingBottom: 15,
            paddingLeft: 10,
            paddingRight: 10,
            height: 80,
            width: 100,
            cursor: 'pointer',
            verticalAlign: 'middle'
        },
        cardIcon: {
            color: '#FFFFFF',
            fontSize: 35,
            margin: 5,
            marginTop: 10,
            verticalAlign: 'middle'
        },
        cardLabel: {
            fontSize: 10,
            fontWeight: '700',
            color: '#FFFFFF'
        },
        primaryButton: {
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: 14,
            borderRadius: 20,
            backgroundColor: '#2784FF',
            border: 'none',
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            float: 'right',
            margin: 20,
            cursor: 'pointer'
        },
        primaryButtonIcon: {
            color: '#FFFFFF',
            fontSize: 15,
            verticalAlign: 'middle'
        },
        selectedStyle: {
            fontSize: 50,
            margin: 8,
            cursor: 'pointer',
            color: '#FFFFFF',
            backgroundColor: '#2784FF',
            borderRadius: 20,
            padding: 5
        },
        panelButton: {
            padding:5,
            backgroundColor: '#62CFFC',
            borderRadius: 8,
            border: 'none',
            marginLeft: 5,
            cursor: 'pointer',
            width: 20,
            height: 20,
            justifyContent: 'center',
            alignItems: 'middle',
            display: 'inline-block'
        },
        activePanelButton: {
            padding:5,
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            border: 'solid 1px #62CFFC',
            color: '#62CFFC',
            marginLeft: 5,
            cursor: 'pointer',
            width: 20,
            height: 20,
            justifyContent: 'center',
            alignItems: 'middle',
            display: 'inline-block'
        },
        buttonIconStyle: {
            fontSize: 22,
            marginRight: 7,
            verticalAlign: 'middle',
            color: '#FFFFFF'
        },
        ActiveButtonIconStyle: {
            fontSize: 22,
            marginRight: 7,
            verticalAlign: 'middle',
            color: '#62CFFC'
        },
        notificationsBadge: {
            backgroundColor: 'red',
            padding: 5,
            paddingTop: 3,
            marginTop: -8,
            marginLeft: -5,
            paddingBottom: 3,
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: 11,
            borderRadius: 20,
            position: 'absolute'
        },
        minMenuContainer: {
            backgroundColor: "#FFFFFF",
            width: expandSideMenu ? 250 : 60,
            display: "flex",
            flexDirection: "column",
            height: '98vh'
        },
        expandedMenuContainer: {
            backgroundColor: "#FFFFFF"
        },
        tabSelectorContainer: {
            display: "flex",
            flexDirection: "row",
            margin: 10,
            marginBottom: 15,
            marginTop: 20
        },
        selectorMenuIcon: {
            color: "#FFFFFF",
            backgroundColor: "#024194",
            padding: 5,
            marginRight: 10,
            borderRadius: 5,
            cursor: 'pointer',
            fontSize: 18
        },
        companyLogoContainer: {
            marginTop: 50,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        companyIcon: {
            width: 80,
            marginTop: 20
        },
        lineSeperator: {
            backgroundColor: '#EAEAEA',
            height: 2
        },
        copyrightLabel: {
            marginTop: 15,
            fontSize: 12
        },
        menuSeperator: {
            display: 'flex',
            flex: 1,
            height: 1,
            backgroundColor: '#E5E5E5'
        }
    }

    const checkAdminStatus = async () => {
        try {
            const response = await SendPost('api/v1/users/check-permission', {
                userId: connectedUser.id,
                authorizationName: "ADMIN",
                componentType: null,
                componentId: null
            });

            return response.status === 200;

        } catch (error) {
            return false;
        }
    };

    return (
        <div style={styles.minMenuContainer}>
            <ExportPopUp isOpen={showExport} handleClose={() => setShowExport(!showExport)} />
            {
                expandSideMenu === null &&
                <div>
                    <SearchBox isVisible={showSearchBar} handleClose={() => setShowSearchBar(false)} />
                    <MinMenuItem iconName="find_in_page" label="Rechercher" onClick={() => { setActiveMenu("Rechercher"); setTabSideMenu("user"); setShowSearchBar(!showSearchBar); }} />
                    <div style={styles.menuSeperator} />
                    <MinMenuItem iconName="grid_on" label="Grid" onClick={() => { navigate("/grid"); setActiveMenu("Grid"); setTabSideMenu("user"); }} />
                    <MinMenuItem iconName="view_list" label="List" onClick={() => { navigate("/list"); setActiveMenu("List"); setTabSideMenu("user"); }} />
                    <MinMenuItem iconName="leaderboard" label="Charts" onClick={() => { navigate("/graphics"); setActiveMenu("Charts"); setTabSideMenu("user"); }} />
                    <div style={styles.menuSeperator} />
                    <MinMenuItem iconName="assignment_turned_in" label="Plan d'action" onClick={() => { navigate("/actionsplan"); setActiveMenu("Plan d'action"); setTabSideMenu("user"); }} />
                    <MinMenuItem iconName="insert_chart" label="Statistiques" onClick={() => { navigate("/statistics"); setActiveMenu("Statistiques"); setTabSideMenu("user"); }} />
                    <MinMenuItem iconName="exit_to_app" label="Export" onClick={() => { setShowExport(true); }} />
                    <MinMenuItem iconName="info" label="A propos" />
                    <MinMenuItem iconName="help" label="Aide" />
                    <div style={styles.menuSeperator} />
                    { !isAdmin && <MinMenuItem active={false} iconName="settings" label="Settings" onClick={() => { setTabSideMenu("admin") ;setActiveMenu("settings"); navigate("/general_settings/1"); }} /> }
                </div>
            }
            {
                expandSideMenu === "user" &&
                <div style={styles.expandedMenuContainer}>

                    {
                        !isAdmin && tabSideMenu === "user" &&
                        <div>
                            <SearchBox isVisible={showSearchBar} handleClose={() => setShowSearchBar(false)} />
                            <FullMenuItem iconName="find_in_page" label="Rechercher" onClick={() => { setActiveMenu("Rechercher"); setShowSearchBar(!showSearchBar) }} />
                            <div style={styles.menuSeperator} />
                            <FullMenuItem iconName="grid_on" label="Grid" onClick={() => { navigate("/grid"); setActiveMenu("Grid"); }} />
                            <FullMenuItem iconName="view_list" label="List" onClick={() => { navigate("/list"); setActiveMenu("List"); }} />
                            <FullMenuItem iconName="leaderboard" label="Charts" onClick={() => { navigate("/graphics"); setActiveMenu("Charts"); }} />
                            <div style={styles.menuSeperator} />
                            <FullMenuItem iconName="assignment_turned_in" label="Plan d'action" onClick={() => { navigate("/actionsplan"); setActiveMenu("Plan d'action"); }} />
                            <FullMenuItem iconName="insert_chart" label="Statistiques" onClick={() => { navigate("/statistics"); setActiveMenu("Statistiques"); }} />
                            <FullMenuItem iconName="exit_to_app" label="Export" onClick={() => { setShowExport(true); }} />
                            <FullMenuItem iconName="info" label="A propos" />
                            <FullMenuItem iconName="help" label="Aide" />
                            <div style={styles.menuSeperator} />
                            { !isAdmin && <FullMenuItem active={false} iconName="settings" label="Admin settings" onClick={() => { setTabSideMenu("admin") ;setActiveMenu("settings"); navigate("/general_settings/1"); }} /> }
                        </div>
                    }

                    {
                        tabSideMenu === "admin" && checkAdminStatus &&
                        <div>
                            <FullMenuItem iconName="house" label="Home" onClick={() => { navigate("/grid"); setActiveMenu("Grid"); setTabSideMenu("user"); }} />
                            <FullMenuItem iconName="account_tree" label="Processus" onClick={() => { navigate("/processus"); setActiveMenu("processus"); }} />
                            <FullMenuItem iconName="list_alt" label="Entités" onClick={() => { navigate("/entites"); setActiveMenu("Entités"); }} />
                            <FullMenuItem iconName="flag" label="Objectifs" onClick={() => { navigate("/objectifs"); setActiveMenu("objectifs"); }} />
                            <FullMenuItem iconName="category" label="Catégorie d'objectifs" onClick={() => { navigate("/categories"); setActiveMenu("Catégorie d'objectifs"); }} />
                            <FullMenuItem iconName="crisis_alert" label="Conséquences potentielles" onClick={() => { navigate("/consequence-potentiel"); setActiveMenu("Conséquences potentielles"); }} />
                            <FullMenuItem iconName="function" label="Formules" onClick={() => { navigate("/riskassessments"); setActiveMenu("Formules"); }} />
                            <FullMenuItem iconName="manage_accounts" label="Gestion des utilisateurs" onClick={() => { navigate("/users"); setActiveMenu("Gestion des utilisateurs"); }} />
                            <FullMenuItem iconName="grid_guides" label="Gestion des templates" onClick={() => { navigate("/templates"); setActiveMenu("Gestion des templates"); }} />
                            <FullMenuItem iconName="alternate_email" label="Paramètres SMTP" onClick={() => { navigate("/smtps"); setActiveMenu("Paramètres SMTP"); }} />
                            <FullMenuItem iconName="settings" label="Settings" onClick={() => { navigate("/general_settings/1"); setActiveMenu("settings"); }} />
                        </div>
                    }

                    <div style={styles.companyLogoContainer} >
                        <div style={styles.lineSeperator} ></div>
                        <img style={styles.companyIcon} src={settings.companyLogo} alt={settings.companyName} />
                        <span style={styles.copyrightLabel} >Copyright &copy; 2023 - 2024 DIGIERM</span>
                    </div>

                </div>
            }
        </div>
    );
}
