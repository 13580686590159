import React from 'react';
import Select, { components } from 'react-select';
import { isEmpty } from '../State/Helper';

export default function RiskSelect({ data, style, value, onChange, itemName, useIcon, iconName="home" }) {

    const info = [{ value: null, label: 'Tout', icon: "globe.png" }];

    data.forEach(item => {
        const option = { value: item.id, label: item.label };
        if (useIcon && item.flag !== undefined) {
            option.icon = item.flag;
        }
        info.push(option);
    });

    let CustomOption = null;

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props} >
                <span className="material-symbols-outlined" style={{ fontSize: '16px', color: '#767676' }}>{iconName}</span>
            </components.DropdownIndicator>
        );
    };

    if (useIcon && !isEmpty(data[0]?.flag)) {
        CustomOption = (props) => (
            <components.Option {...props}>
                <img
                    src={require(`../assets/images/${props.data.icon}`)}
                    style={{ width: 20, height: 18, marginRight: 10, verticalAlign: 'middle' }}
                    alt={props.data.label}
                />
                {props.data.label}
            </components.Option>
        );
    } else {
        CustomOption = (props) => (
            <components.Option {...props}>
                {props.data.label}
            </components.Option>
        );
    }

    const selectedOption = info.find(option => parseInt(option.value) === parseInt(value.value));

    const handleSelectChange = (selectedOption) => {
        onChange(selectedOption);
        localStorage.setItem(itemName, JSON.stringify(selectedOption));
    };

    return (
        <Select
            value={selectedOption}
            onChange={handleSelectChange}
            defaultValue={info[0]}
            options={info}
            components={{ Option: CustomOption, DropdownIndicator }}
            isSearchable={true}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    color: '#000000'
                }),
                control: (baseStyles) => ({
                    ...baseStyles,
                    ...style
                }),
                placeholder: (defaultStyles) => {
                    return {
                        ...defaultStyles,
                        color: '#000000',
                    }
                },
                option: (baseStyles) => {
                    return {
                        ...baseStyles,
                        ...style,
                        marginTop: 5
                    }
                },
                input: (baseStyles) => {
                    return {
                        ...baseStyles,
                        color: '#000000'
                    }
                },
                singleValue: (baseStyles) => {
                    return {
                        ...baseStyles,
                        color: '#000000'
                    }
                },
                indicatorSeparator: () => ({
                    display: 'none', // Hide the default indicator separator
                }),
                dropdownIndicator: (provided) => ({
                    ...provided
                }),
            }}
        />
    );
}
