import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {useEffect, useState} from "react";
import {SendGet, getColorHexCode} from "../State/Helper";
import { useAuth } from "../State/useAuth";
import { useNavigate } from "react-router-dom";

export default function ActionsPlan() {

    const [showSideMenu, setShowSideMenu] = useState(true);
    const [riskList, setRiskList] = useState([]);
    const [activeActionsCounter, setActiveActionsCounter] = useState(0);
    const [doneActionsCounter, setDoneActionsCounter] = useState(0);
    const [conciderActionsCounter, setConciderActionsCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {processus, entite, objectif} = useAuth();

    const styles = {
        container: {
            backgroundColor: '',
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            flex: 1
        },
        bodyContainer: {
            display: 'flex',
            flex: 1
        },
        cardRow: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row'
        },
        notificationsContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        boxNotifications: {
            backgroundColor: '#FFFFFF',
            borderRadius: 10,
            marginRight: 15,
            padding: 10,
            border: 'solid 1px #B3B3B3'
        },
        notificationFont: {
            color: '#000000',
            fontSize: 14,
            padding: 15
        },
        iconStyle:
        {
            fontSize: 16,
            fontWeight: 700,
            color: '#000000',
            verticalAlign: 'middle'
        },
        listContainer:
        {
            marginTop: 20,
            flex: 1
        },
        actionContainer:
        {
            backgroundColor: '#FFFFFF',
            padding: 10,
            marginBottom: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 10
        },
        riskHeaderContainer:
        {
            backgroundColor: 'grey',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 10,
            paddingLeft: 10
        },
        riskHeaderLabelContainer:
        {
            display: 'flex',
            flexDirection: 'row',
            flex: 5,
            fontSize: 14,
            padding: 15,
            color: '#FFFFFF'
        },
        riskHeaderButtonsContainer:
        {
            display: 'flex',
            flexDirection: 'row',
            flex: 1
        },
        tagContainer:
        {
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: 5,
            marginRight: 10
        },
        actionsList:
        {
            padding: 20
        }
    }

    // Get risks list
    async function getRiskList() {

        try
        {
            // Get the risks list
            const response = await SendGet(`api/v1/risks/actionplan`);

            // Initialize the counters
            let activeActions = 0;
            let doneActions = 0;
            let conciderActions = 0;

            // Check if the risk response actually has some risks
            if ( response.data !== undefined && Array.isArray(response.data) && response.data.length > 0 ) {

                // Get actions for each risk
                const dataWithRiskId = await Promise.all(response.data.map(async item => {

                    // Get actions
                    let actionResponse = await getActions(item.id);

                    if( actionResponse !== undefined && Array.isArray(actionResponse) && actionResponse.length > 0 )
                    {
                        // Loop through the found actions in order to filter them by status
                        actionResponse.map(action => {
                            switch( String(action.status).toLocaleLowerCase() )
                            {
                                case 'active':
                                    activeActions++;
                                    break;
                                case 'completed':
                                    doneActions++;
                                    break;
                                case 'concider':
                                    conciderActions++;
                                    break;
                            }
                        });
                    }

                    // Get risk brute
                    let activeAssessment = await getAssessment(item.id);

                    if( typeof activeAssessment === 'object' )
                    {
                        return actionResponse !== null ? { ...item, actions: actionResponse, criticite: activeAssessment.criticite, gravityImpact: activeAssessment.gravityImpact } : item;
                    }
                    else
                    {
                        return actionResponse !== null ? { ...item, actions: actionResponse, criticite: 0 } : item;
                    }

                }));

                // Pre-parsing filter values
                const parsedProcessus = processus.value !== null ? parseInt(processus.value) : null;
                const parsedEntite = entite.value !== null ? parseInt(entite.value) : null;
                const parsedObjectif = objectif.value !== null ? parseInt(objectif.value) : null;

                // Filter risks
                let risks = dataWithRiskId.filter(item =>
                    (parsedProcessus === null || parseInt(item.processusId) === parsedProcessus) &&
                    (parsedEntite === null || parseInt(item.entiteId) === parsedEntite) &&
                    (parsedObjectif === null || parseInt(item.objectifId) === parsedObjectif)
                );

                // Sort the risk list
                risks = risks.sort((a, b) => b.credate - a.credate);

                setRiskList(risks);

                setActiveActionsCounter(activeActions);
                setDoneActionsCounter(doneActions);
                setConciderActionsCounter(conciderActions);
            }

        } catch (error)
        {
            console.error("Failed to fetch risks:", error);
        }
    }

    async function getActions(riskId) {

        const response = await SendGet(`api/v1/actions/risk/${riskId}`);

        if( response.data !== undefined && Array.isArray(response.data) && response.data.length > 0 )
        {
            return response.data;
        }
        else
        {
            return [];
        }
    }

    async function getAssessment(riskId) {

        const rsp = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if(rsp.data !== undefined && Array.isArray(rsp.data))
        {
            return rsp.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), rsp.data[0]);
        }
        else
        {
            return null;
        }
    }

    useEffect(() => {
       
       async function fetch() {
            await getRiskList();
       }
       
       try {
            setLoading(true);
            fetch();
       } catch (error) {
            console.log(error);
       } finally {
           setLoading(false);
       }
       
    }, [processus, entite, objectif]);

    return (
        <div>
            <RiskTopMenu />
            
            { loading && <div style={{ width: 100, margin: '0px auto' }}><img src="/loading.gif" width={50} style={{alignSelf: 'center', marginTop: 50}}/></div> }
            
            <div style={{ display: "flex", flexDirection: "row" }}>

                <RiskSideMenu showSideMenu={showSideMenu} />
                
                <div style={styles.bodyContainer}>
                    <div style={styles.container}>

                        <div style={styles.notificationsContainer}>
                            <div style={styles.boxNotifications}>
                                <span style={styles.notificationFont}><span className="material-symbols-outlined" style={styles.iconStyle}>cancel</span> {activeActionsCounter} Actions à effectuer</span>
                            </div>
                            <div style={styles.boxNotifications}>
                                <span style={styles.notificationFont}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> {doneActionsCounter} Actions effectuées</span>
                            </div>
                            <div style={styles.boxNotifications}>
                                <span style={styles.notificationFont}><span className="material-symbols-outlined" style={styles.iconStyle}>warning</span> {conciderActionsCounter} Actions à considérer</span>
                            </div>
                        </div>

                        <div style={styles.listContainer}>
                            {
                                Array.isArray(riskList) && riskList.map((item, index) => (
                                    <div style={styles.actionContainer} key={index}>
                                        <div style={{ ...styles.riskHeaderContainer, backgroundColor: getColorHexCode(item.criticite) }}>
                                            <div style={styles.riskHeaderLabelContainer}>
                                                <span style={styles.tagContainer}><span style={styles.iconStyle} className="material-symbols-outlined">barcode</span> {item.origin}</span>
                                                <span style={styles.tagContainer}><span style={styles.iconStyle} className="material-symbols-outlined">barcode</span> {item.frTitle}</span>
                                            </div>
                                            <div style={styles.riskHeaderButtonsContainer}>
                                            </div>
                                        </div>
                                        <div style={styles.actionsList}>
                                            {item.actions.map((action, index) => (
                                                String(action.status).toLowerCase() === "active" &&
                                                (
                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, border: 'solid 1px #62CFFC', padding: 10, borderRadius: 10 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 5.5 }}>
                                                            <span style={{ color: '#FFFFFF', backgroundColor: '#D90303', fontSize:18, height: 19, borderRadius: '50%', verticalAlign: 'middle', marginRight: 10 }} className="material-symbols-outlined">cancel</span>
                                                            <div>
                                                                <span style={{ color: '#000000', fontSize: 14 }}>{action.frContent}</span> <br />
                                                                <span style={{ color: '#000000', fontSize: 12, fontWeight: 700 }}><span style={{ color: '#FFFFFF', backgroundColor: '#62CFFC', fontSize:18, height: 19, borderRadius: '50%', verticalAlign: 'middle', marginRight: 10, marginTop: 10 }} className="material-symbols-outlined">target</span> {action.frGoal}</span>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', cursor:'pointer' }}>
                                                            <span onClick={() => navigate(`/action/edit/${action.riskId}/${action.id}`)} style={{ verticalAlign: 'middle', height: 18, fontSize:18, color: '#FFFFFF', padding: 10, borderRadius: '50%', backgroundColor: '#2784FF' }} className="material-symbols-outlined">edit</span>
                                                        </div>
                                                    </div>
                                                ) ||
                                                String(action.status).toLowerCase() === "completed" &&
                                                (
                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, border: 'solid 1px #62CFFC', padding: 10, borderRadius: 10 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 5.5 }}>
                                                            <span style={{ color: '#FFFFFF', backgroundColor: '#62CFFC', fontSize:18, height: 19, borderRadius: '50%', verticalAlign: 'middle', marginRight: 10 }} className="material-symbols-outlined">check</span>
                                                            <div>
                                                                <span style={{ color: '#000000', fontSize: 14 }}>{action.frContent}</span> <br />
                                                                <span style={{ fontSize: 12, fontWeight: 700, color: '#328AFF' }}><span style={{ color: '#FFFFFF', backgroundColor: '#62CFFC', fontSize:18, height: 19, borderRadius: '50%', verticalAlign: 'middle', marginRight: 10, marginTop: 10 }} className="material-symbols-outlined">target</span> {action.frGoal}</span>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', cursor:'pointer' }}>
                                                            <span onClick={() => navigate(`/action/edit/${action.riskId}/${action.id}`)} style={{ verticalAlign: 'middle', height: 18, fontSize: 18, color: '#FFFFFF', padding: 10, borderRadius: '50%', backgroundColor: '#2784FF' }} className="material-symbols-outlined">edit</span>
                                                        </div>
                                                    </div>
                                                ) ||
                                                String(action.status).toLowerCase() === "concider" &&
                                                (
                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, border: 'solid 1px #62CFFC', padding: 10, borderRadius: 10 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 5.5 }}>
                                                            <span style={{ color: '#FFFFFF', backgroundColor: '#024194', fontSize:18, height: 19, borderRadius: '50%', verticalAlign: 'middle', marginRight: 10 }} className="material-symbols-outlined">warning</span>
                                                            <div>
                                                                <span style={{ color: '#000000', fontSize: 14 }}>{action.frContent}</span> <br />
                                                                <span style={{ fontSize: 12, fontWeight: 700, color: '#328AFF' }}><span style={{ color: '#FFFFFF', backgroundColor: '#62CFFC', fontSize:18, height: 19, borderRadius: '50%', verticalAlign: 'middle', marginRight: 10 }} className="material-symbols-outlined">target</span> {action.frGoal}</span>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', cursor:'pointer' }}>
                                                            <span onClick={() => navigate(`/action/edit/${action.riskId}/${action.id}`)} style={{ verticalAlign: 'middle', height: 18, fontSize: 18, color: '#FFFFFF', padding: 10, borderRadius: '50%', backgroundColor: '#2784FF' }} className="material-symbols-outlined">edit</span>
                                                        </div>
                                                    </div>
                                                )
                                            ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}
