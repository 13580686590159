import React from 'react';

const RiskMatrix = () => {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: 800,
            margin: '0px auto'
        },
        mainTitle: {
            fontSize: 14,
            fontWeight: 600,
            textAlign: 'center'
        },
        table: {
            borderCollapse: 'collapse'
        },
        column: {
            width: 114,
            textAlign: 'center',
            height: 40
        }
    };

    return (
        <div style={styles.container}>
            <p style={styles.mainTitle}></p>
            <table style={styles.table} border={1}>
                <tbody>
                <tr>
                    <td style={{ ...styles.column, transform: 'rotate(90deg)' }} rowSpan={6}>Gravité des impacts</td>
                    <td style={styles.column}>5</td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF54' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FEBF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FEBF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FF0000' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FF0000' }}></td>
                </tr>
                <tr>
                    <td style={styles.column}>4</td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF54' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF54' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FEBF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FF0000' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FF0000' }}></td>
                </tr>
                <tr>
                    <td style={styles.column}>3</td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF54' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF54' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FEBF00' }}>9</td>
                    <td style={{ ...styles.column, backgroundColor: '#FEBF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FEBF00' }}></td>
                </tr>
                <tr>
                    <td style={styles.column}>2</td>
                    <td style={{ ...styles.column, backgroundColor: '#F4B084' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF00' }}>3</td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FEBF00' }}>1</td>
                </tr>
                <tr>
                    <td style={styles.column}>1</td>
                    <td style={{ ...styles.column, backgroundColor: '#F4B084' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#F4B084' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF00' }}></td>
                    <td style={{ ...styles.column, backgroundColor: '#FFFF00' }}></td>
                </tr>
                <tr>
                    <td style={styles.column}></td>
                    <td style={styles.column}>1</td>
                    <td style={styles.column}>2</td>
                    <td style={styles.column}>3</td>
                    <td style={styles.column}>4</td>
                    <td style={styles.column}>5</td>
                </tr>
                <tr>
                    <td style={styles.column} colspan={2}></td>
                    <td style={styles.column} colSpan={5}>Probabilité d'Occurrence</td>
                </tr>
                </tbody>
            </table>

            <table style={{ marginTop: 20 }}>
                <tbody>
                    <tr>
                        <td style={{ width: 200, backgroundColor: '#FF0000' }}></td>
                        <td>Criticité Très élevée</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200, backgroundColor: '#FEBF00' }}></td>
                        <td>Criticité Pultot élevée</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200, backgroundColor: '#FFFF00' }}></td>
                        <td>Criticité Plutot faible</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200, backgroundColor: '#F4B084' }}></td>
                        <td>Criticité Très faible</td>
                    </tr>
                </tbody>
            </table>

        </div>
    );

};

export default RiskMatrix;