import {useEffect, useState} from "react";
import {SendGet, getColorHexCode, isEmpty} from "../State/Helper";
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";

export default function RiskCardList({ risk, frTitle }) {

    const [showPopup, setShowPopup] = useState(false);
    const [theme, setTheme] = useState('');
    const navigate = useNavigate();

    const geTheme = async () => {

        const response = await SendGet(`api/v1/themes/${risk.themeId}`);

        if( !isEmpty(response.data) )
        {
            setTheme(response.data.frTitle);
        }
    }

    useEffect(() => {

        async function fetchData()
        {
            if( !isEmpty(risk.calcs) )
            {
                // Get the theme title
                await geTheme();
            }
            else
            {
                setTheme('');
            }
        }

        fetchData();

    }, [risk, frTitle]);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: !isEmpty(risk.calcs) ? getColorHexCode( risk.calcs.criticite ) : '#55657B',
            borderRadius: 5,
            cursor: 'pointer',
            marginBottom: 10,
            paddingRight: 10,
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            padding: 20,
            flex: 3
        },
        tagContainer: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 8,
            marginLeft: 5,
            height: 35
        },
        tagText: {
            fontSize: 11,
            fontWeight: 700,
            color: '#FFFFFF'
        },
        body: {
            padding: 5,
            width: 350
        },
        bodyTitle: {
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#FFFFFF',
            fontSize: 12,
            marginLeft: 20
        },
        detailsContainer: {
            paddingLeft: 15,
            paddingBottom: 20
        },
        detailsRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: -8
        },
        detailLabel: {
            flex: 2.5,
            fontSize: 11,
            fontWeight: 700,
            color: '#FFFFFF',
        },
        detailRating: {
            flex: 4.5,
            paddingTop: 8,
        },
        iconStyle: {
            fontSize: 15,
            marginRight: 5,
            verticalAlign: 'middle'
        },
        fullRate: {
            fontSize: 10,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#EFCA20',
            backgroundColor: '#EFCA20',
            borderRadius: '50%'
        },
        emptyRate: {
            fontSize: 10,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#EFCA20',
        },
        infoPopup: {
            backgroundColor: '#D0C401',
            width: 90,
            alignItems: 'center'
        },
        popUpIcon: {
            display: 'flex',
            fontSize: 40,
            color: '#FFFFFF',
            margin: 25,
            marginBottom: 0,
            marginTop: 0,
            paddingTop: 5
        },
        textPopup: {
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: '700',
            textAlign: 'center'
        },
        popUpNumber: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: -28,
            fontWeight: '700',
            color: '#FFFFFF'
        }
    }

    if( isEmpty(risk) )
    {
        return (
            <div>
                <p>Loading ...</p>
            </div>
        );
    }

    return (<div style={styles.container} onClick={() => navigate(`/risks/detail/${risk.id}`)}>
                <div style={styles.header}>
                    <div style={styles.tagContainer}>
                        <p style={styles.tagText}><span className="material-symbols-outlined" style={styles.iconStyle}>sell</span>{theme}</p>
                    </div>
                    <div style={styles.tagContainer}>
                        <p style={styles.tagText}><span className="material-symbols-outlined" style={styles.iconStyle}>info</span>{frTitle}</p>
                    </div>
                    <div style={styles.bodyTitle}>
                    </div>
                </div>
                <div>
                    {   showPopup &&
                        <div style={styles.infoPopup}>
                            <span className="material-symbols-outlined" style={styles.popUpIcon}>circle</span>
                            <p style={styles.popUpNumber}>2</p>
                            <p style={styles.textPopup}>Occurrence</p>
                        </div>
                    }
                    <div style={styles.body}>
                        <div style={styles.detailsContainer}>
                            <div style={styles.detailsRow}>
                                <p style={styles.detailLabel}>Impact</p>
                                <div style={styles.detailRating}>
                                    <ProgressBar
                                        completed={ !isEmpty(risk.calcs) ? Math.floor( ( risk.calcs.gravityImpact / 5 ) * 100 ) : 0 }
                                        labelSize={9}
                                        className="progressBarwrapper"
                                        bgColor="#FFFFFF"
                                        labelColor={ !isEmpty(risk.calcs) ? getColorHexCode( Number(risk.calcs.criticite) ) : '#FFFFFF' }
                                        baseBgColor="rgba(255, 255, 255, 0.3)"
                                    />
                                </div>
                            </div>
                            <div style={styles.detailsRow}>
                                <p style={styles.detailLabel}>Occurrence</p>
                                <div style={styles.detailRating}>
                                    <ProgressBar
                                        completed={ !isEmpty(risk.calcs) ? Math.floor( ( risk.calcs.occurrence / 5 ) * 100 ) : 0 }
                                        labelSize={9}
                                        className="progressBarwrapper"
                                        bgColor="#FFFFFF"
                                        labelColor={ !isEmpty(risk.calcs) ? getColorHexCode( Number( risk.calcs.criticite ) ) : '#FFFFFF' }
                                        baseBgColor="rgba(255, 255, 255, 0.3)"
                                    />
                                </div>
                            </div>
                            <div style={styles.detailsRow}>
                                <p style={styles.detailLabel}>Maitrise</p>
                                <div style={styles.detailRating}>
                                    <ProgressBar
                                        completed={ !isEmpty(risk.calcs) ? (Number(risk.calcs.maitrise)/4 * 100) : 0 }
                                        labelSize={9}
                                        labelColor={ !isEmpty(risk.calcs) ? getColorHexCode( Number( risk.calcs.criticite ) ) : '#FFFFFF' }
                                        className="progressBarwrapper"
                                        bgColor="#FFFFFF"
                                        baseBgColor="rgba(255, 255, 255, 0.3)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}
