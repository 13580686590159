import {getColorHexCode, isEmpty} from "../State/Helper";
import React, {useState} from "react";
import {useAuth} from "../State/useAuth";

export default function SearchBox({ isVisible, handleClose }) {

    const styles = {
        container: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1001
        },
        contentStyle: {
            background: '#FFFFFF',
            padding: 30,
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
            position: 'relative',
            color: '#000000'
        },
        closeButtonStyle: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '18px',
            cursor: 'pointer',
        },
        primaryButton: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            fontWeight: 700,
            height: 35,
            padding: 10
        },
        inputSelect: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            width: 250
        },
        iconStyle: {
            fontSize: 18,
            color: '#FFFFFF',
            cursor: 'pointer',
            fontWeight: 700,
            verticalAlign: 'middle',
        }
    }
    const { setSearchKeyword, setRankFilter, rankFilter } = useAuth();
    const [keyword, setKeyword] = useState('');

    const searchData = () => {
        if( isEmpty(keyword) )
        {
            setSearchKeyword( { value: "", label: "" } );
        }
        else
        {
            setSearchKeyword( { value: keyword, label: "" } )
        }
    }

    const applyFilter = (rank) => {
        setRankFilter(rank);
    }

    return (
        isVisible &&
        <div style={styles.container} onKeyDown={e => e.key === "Enter" ? searchData() : null} >
            <div style={styles.contentStyle}>
                <span style={styles.closeButtonStyle} onClick={handleClose}>&times;</span>
                <input type="text" placeholder="Mot-clé ..." style={styles.inputSelect} onChange={(e) => setKeyword(e.target.value)}/>
                <button onClick={searchData} style={styles.primaryButton}><span className="material-symbols-outlined" style={styles.iconStyle}>search</span></button>
                <div style={{display: "flex", flexDirection: "row", marginTop: 15}}>

                    <div onClick={() => applyFilter("not_evaluated")}
                         style={{
                             flex: 1,
                             height: 23,
                             cursor: "pointer",
                             paddingTop: 7,
                             color: "#FFFFFF",
                             fontSize: 14,
                             backgroundColor: getColorHexCode(null),
                             border: rankFilter === "not_evaluated" ? "solid 2px #2F87FB" : ""
                         }}>N/A
                    </div>

                    <div onClick={() => applyFilter(4)}
                         style={{
                             flex: 1,
                             height: 23,
                             cursor: "pointer",
                             paddingTop: 7,
                             color: "#FFFFFF",
                             fontSize: 14,
                             backgroundColor: getColorHexCode(4),
                             border: rankFilter === 4 ? "solid 2px #2F87FB" : ""
                         }}>4
                    </div>
                    <div onClick={() => applyFilter(3)}
                         style={{
                             flex: 1,
                             height: 23,
                             cursor: "pointer",
                             paddingTop: 7,
                             color: "#FFFFFF",
                             fontSize: 14,
                             backgroundColor: getColorHexCode(3),
                             border: rankFilter === 3 ? "solid 2px #2F87FB" : ""
                         }}>3
                    </div>
                    <div onClick={() => applyFilter(2)}
                         style={{
                             flex: 1,
                             height: 23,
                             cursor: "pointer",
                             paddingTop: 7,
                             color: "#FFFFFF",
                             fontSize: 14,
                             backgroundColor: getColorHexCode(2),
                             border: rankFilter === 2 ? "solid 2px #2F87FB" : ""
                         }}>2
                    </div>
                    <div onClick={() => applyFilter(1)}
                         style={{
                             flex: 1,
                             height: 23,
                             cursor: "pointer",
                             paddingTop: 7,
                             color: "#FFFFFF",
                             fontSize: 14,
                             backgroundColor: getColorHexCode(1),
                             border: rankFilter === 1 ? "solid 2px #2F87FB" : ""
                         }}>1
                    </div>

                    <div onClick={() => applyFilter(null)}
                         style={{
                             border: 'solid 1px #2F87FB',
                             borderRadius: 5,
                             height: 27,
                             width: 35,
                             marginLeft: 5,
                             cursor: "pointer",
                             color: "#2F87FB",
                             backgroundColor: "#FFFFFF",
                             paddingTop: 2
                         }}>
                        <span className="material-symbols-outlined">restart_alt</span>
                    </div>

                </div>
            </div>
        </div>
    )

}