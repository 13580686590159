import {convertToPercentage, getColorHexCode, isEmpty, SendGet} from "../State/Helper";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useAuth} from "../State/useAuth";

export default function RiskCard({ risk }) {

    const [theme, setTheme] = useState('');

    const [impactPercentage, setImpactPercentage] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const [occurrencePercentage, setOccurrencePercentage] = useState(0);
    const [margeProgresPercentage, setMargeProgresPercentage] = useState(0);
    const [screenWidth, setScreenWidth] = useState(null);
    const [screenHeight, setScreenHeight] = useState(null);
    const [cardNWidth, setCardNWidth] = useState(335);
    const [cardCWidth, setCardCWidth] = useState(335);
    const [menuWidth, setMenuWidth] = useState(148);
    const [ModRest, setModRest] = useState(0);
    const [cardsNumber, setCardsNumber] = useState(0);
    const { expandSideMenu } = useAuth();

    const navigate = useNavigate();

    // Get theme
    const geTheme = async () => {
        const response = await SendGet(`api/v1/themes/${risk.themeId}`);
        if( response.data !== undefined )
        {
            setTheme(response.data.frTitle);
        }
    }

    // Set default value for the screen dimensions
    useEffect(() => {

        // Function to update the window size
        const handleResize = () => {

          setScreenWidth( window.innerWidth );
          setScreenHeight( window.innerHeight );
        
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {

        // Set the size of the menu according to it's state expanded or not
        if( expandSideMenu )
        {
            setMenuWidth(298);
        }
        else
        {
            setMenuWidth(148);
        }
        
        let mod = (screenWidth - menuWidth) % (335+5);
        let cardNumber = (screenWidth - menuWidth - mod) / (cardNWidth+5);
        let newCardWidth = cardNWidth + mod /cardNumber;

        setCardsNumber( cardNumber );
        
        if( (mod - (5* ( cardNumber ))) > 0 )
        {
            setCardCWidth( newCardWidth );
        }

    }, [screenWidth, risk]);

    useEffect(() => {
        async function fetchData() {
            setTheme('');
            await geTheme();
            calculatePercentage();
        }
        fetchData();
    }, [risk]);

    const calculatePercentage = () => {

        let impactValue = 0;
        let occurrenceValue = 0;
        let marginPercentage = 0;

        if( !isEmpty(risk.calcs) )
        {
            impactValue = parseFloat(risk.calcs.gravityImpact).toFixed(2) / 5 * 100;
            occurrenceValue = parseFloat(risk.calcs.occurrence).toFixed(2) / 5 * 100;
            marginPercentage = Number( risk.calcs.maitrise );

            setImpactPercentage( Math.round( impactValue ) );
            setOccurrencePercentage( Math.round( occurrenceValue ) );
            setMargeProgresPercentage( risk.calcs.maitrise );
        }
    }

    useEffect(() => {
        
        // Dispatch a resize event on page load
        window.dispatchEvent(new Event('resize'));

    }, []);

    const styles = {
        container: {
            backgroundColor: !isEmpty(risk.calcs) ? getColorHexCode(risk.calcs.criticite) : '#55657B',
            width: cardCWidth,
            height: 260,
            marginLeft: 10,
            marginBottom: 10,
            borderRadius: 3,
            cursor: 'pointer',
            boxShadow: '2px 2px 4px 0 #E2E2E2',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            padding: 20,
            textTransform: 'uppercase'
        },
        tagContainer: {
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 10,
            borderRadius: 8
        },
        tagText: {
            fontSize: 13,
            fontWeight: 700,
            color: '#FFFFFF'
        },
        body: {
            padding: 5
        },
        bodyTitle: {
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            fontSize: 13,
            height: 30
        },
        detailsContainer: {
            paddingBottom: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
        detailsRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: -10
        },
        detailLabel: {
            flex: 2.5,
            fontSize: 12,
            color: '#FFFFFF',
            fontWeight: 700
        },
        detailRating: {
            flex: 4.5,
            paddingTop: 8,
        },
        iconStyle: {
            fontSize: 15,
            marginRight: 5,
            verticalAlign: 'middle'
        },
        fullRate: {
            fontSize: 15,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#EFCA20',
            backgroundColor: '#EFCA20',
            borderRadius: '50%'
        },
        emptyRate: {
            fontSize: 15,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#EFCA20',
        },
        progressContainer: {
            width: 60,
            height: 60,
            flex: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: 40
        },
        progressLabel: {
            color: '#FFFFFF',
            fontSize: 13
        }
    }

    return (
        <div style={styles.container} onClick={() => navigate(`/risks/detail/${risk.id}`)}>
            <div style={styles.header}>
                <div style={styles.tagContainer}>
                    <p style={styles.tagText}><span className="material-symbols-outlined" style={styles.iconStyle}>sell</span>{theme}</p>
                </div>
            </div>
            <div style={styles.body}>
                <div style={styles.bodyTitle}>
                    <p>{String(risk.frTitle).substring(0, 60)} { String(risk.frTitle).length > 60 ? '...' : '' }</p>
                </div>
                <div style={styles.detailsContainer}>

                    <div style={styles.progressContainer}>
                        <div style={{ width: 60, height: 60 }}>
                            <CircularProgressbar
                                value={ impactPercentage }
                                text={ `${impactPercentage}%` }
                                size={40}
                                styles={buildStyles({ rotation: 0, strokeLinecap: 'butt', textSize: '24px', pathTransitionDuration: 0.5, pathColor: '#FFFFFF', textColor: '#FFFFFF', trailColor: 'rgba(255, 255, 255, 0.3)'})}
                            />
                        </div>
                        <p style={styles.progressLabel}>Impact</p>
                    </div>

                    <div style={styles.progressContainer}>
                        <div style={{ width: 60, height: 60 }}>
                            <CircularProgressbar
                                value={ occurrencePercentage }
                                text={ `${occurrencePercentage}%` }
                                size={40}
                                styles={buildStyles({rotation: 0, strokeLinecap: 'butt', textSize: '24px', pathTransitionDuration: 0.5, pathColor: '#FFFFFF', textColor: '#FFFFFF', trailColor: 'rgba(255, 255, 255, 0.3)' })}
                            />
                        </div>
                        <p style={styles.progressLabel}>Occurrence</p>
                    </div>

                    <div style={styles.progressContainer}>
                        <div style={{ width: 60, height: 60 }}>
                            <CircularProgressbar
                                value={ convertToPercentage(margeProgresPercentage) }
                                text={ `${ convertToPercentage(margeProgresPercentage) }%` }
                                size={40}
                                styles={buildStyles({rotation: 0, strokeLinecap: 'butt', textSize: '24px', pathTransitionDuration: 0.5, pathColor: '#FFFFFF', textColor: '#FFFFFF', trailColor: 'rgba(255, 255, 255, 0.3)' })}
                            />
                        </div>
                        <p style={styles.progressLabel}>Maitrise</p>
                    </div>

                </div>
            </div>
        </div>
    );
}
