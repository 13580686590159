import React, { useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { SendGet } from './Helper';

export const AuthProvider = ({ children }) => {

    const [auth, setAuth] = useState({ accessToken: null, refreshToken: null, user: null });
    const [isLoading, setIsLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState('Grid');
    const [expandSideMenu, setExpandSideMenu] = useState(null);
    const [tabSideMenu, setTabSideMenu] = useState("user");
    const [searchKeyword, setSearchKeyword] = useState('');
    const [rankFilter, setRankFilter] = useState(null);
    const [settings, setSettings] = useState();
    const [processus, setProcessus] = useState();
    const [entite, setEntite] = useState();
    const [objectif, setObjectif] = useState();
    const [activePagination, setActivePagination] = useState(1);
    const [activeRiskMenu, setActiveRiskMenu] = useState("general");

    useEffect(() => {

        let accessToken = localStorage.getItem('accessToken');
        let refreshToken = localStorage.getItem('refreshToken');
        let user = localStorage.getItem('user');
        let processusFilter = JSON.parse(localStorage.getItem('processus'));
        let entiteFilter = JSON.parse(localStorage.getItem('entite'));
        let objectifFilter = JSON.parse(localStorage.getItem('objectif'));
        let searchKeywordFilter = JSON.parse(localStorage.getItem('searchKeyword'));
        let activePaginationFilter = JSON.parse(localStorage.getItem('activePagination'));
        let activeRiskMenuFilter = JSON.parse(localStorage.getItem('activeRiskMenu'));

        // Validate the user access token
        if (accessToken && refreshToken && user) {
            SendGet(`api/v1/auth/validate-token?token=${accessToken}`)
            .then(response => {
                if (response.data) {
                    // Token is valid
                    setAuth({ accessToken, refreshToken, user: JSON.parse(user) });
                } else {
                    // Token is not valid
                    logout();
                }
                setIsLoading(false);
            })
            .catch(error => {
                logout();
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }

        // Apply filters if any is found in the sessions local storage
        if(processusFilter) { setProcessus(processusFilter); }
        if(entiteFilter) { setEntite(entiteFilter); }
        if(objectifFilter) { setObjectif(objectifFilter); }
        if(searchKeywordFilter) { setSearchKeyword(searchKeywordFilter); }
        if(activePaginationFilter) { setActivePagination(activePaginationFilter); }
        if(activeRiskMenuFilter) { setActiveRiskMenu(activeRiskMenu); }

        getSettings();

    }, []);

    async function getSettings() {
        const response = await SendGet("api/v1/general-settings/1");
        setSettings(response.data);
    }

    const login = (accessToken, refreshToken, user) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));
        setAuth({ accessToken, refreshToken, user });
    };

    const logout = () => {
        localStorage.clear();
        setAuth({ accessToken: null, refreshToken: null, user: null });
        window.location.href = "/login";
    };

    return (
        <AuthContext.Provider value={{ auth, isLoading, setIsLoading, login, logout, activeMenu, setActiveMenu, searchKeyword, setSearchKeyword, expandSideMenu, setExpandSideMenu, tabSideMenu, setTabSideMenu, settings, rankFilter, setRankFilter, processus, setProcessus, entite, setEntite, objectif, setObjectif, activePagination, setActivePagination, activeRiskMenu, setActiveRiskMenu }}>
            {!isLoading ? children : <div>Loading...</div>}
        </AuthContext.Provider>
    );
};
