export default function RiskLeveling() {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        column: {
            flex: 2,
            margin: 5,
            borderRadius: 10,
            padding: 10
        },
        labelNumber: {
            fontSize: 14,
            fontWeight: 700,
            backgroundColor: '#FFFFFF',
            padding: 2,
            borderRadius: '50%'
        },
        labelTitle: {
            fontSize: 12,
            fontWeight: 700
        },
        labelPrice: {
            fontSize: 12,
            fontWeight: 700
        }
    }

    return (
        <div style={styles.container}>

            <div style={styles.row}>
                <div style={{...styles.column, backgroundColor: '#FFE6E6', border: 'solid 1px #985353'}}>
                    <span style={{...styles.labelNumber, color: '#985353'}}>4</span> <br/>
                    <span style={{...styles.labelPrice, color: '#985353'}}>Criticité très élevée</span>
                </div>
                <div style={{...styles.column, backgroundColor: '#FFEBE8', border: 'solid 1px #C27166'}}>
                    <span style={{...styles.labelNumber, color: '#C27166'}}>3</span> <br/>
                    <span style={{...styles.labelPrice, color: '#C27166'}}>Criticité pultot élevée</span>
                </div>
                <div style={{...styles.column, backgroundColor: '#FEFAF0', border: 'solid 1px #8B7435'}}>
                    <span style={{...styles.labelNumber, color: '#8B7435'}}>2</span> <br/>
                    <span style={{...styles.labelPrice, color: '#8B7435'}}>Criticité plutot faible</span>
                </div>
                <div style={{...styles.column, backgroundColor: '#E9F8EF', border: 'solid 1px #72B68C'}}>
                    <span style={{...styles.labelNumber, color: '#72B68C'}}>1</span> <br/>
                    <span style={{...styles.labelPrice, color: '#72B68C'}}>Criticité très faible</span>
                </div>
            </div>

        </div>
    );
}