import {convertDateToString, getConnectedUser, isEmpty, SendGet, SendPost} from "../State/Helper";
import {useState, useEffect} from "react";

export default function Comment({ id, comment, fullname, credate, replies, getComments, riskId, creatorId }) {

    const [commentText, setCommentText] = useState("");
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const connectedUser = getConnectedUser();

    const styles = {
        container: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #000000',
            borderRadius: 10,
            padding: 10,
            marginBottom: 10
        },
        nameLabel: {
            fontWeight: 700,
            fontSize: 14,
            color: '#000000'
        },
        commentLabel: {
            fontSize: 14,
            color: '#808080'
        },
        dateLabel: {
            fontSize: 12,
            color: "grey",
            fontWeight: 700
        },
        seperator: {
            backgroundColor: '#808080'
        },
        replyBtn: {
            float: 'right',
            margin: 5,
            fontSize: 12,
            fontWeight: 700,
            cursor: 'pointer',
            color: '#000000'
        },
        commentBtn: {
            backgroundColor: '#FFFFFF',
            color: 'grey',
            border: 'solid 1px grey',
            borderRadius: 10,
            padding: 4,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
            float: 'right',
            fontWeight: 700,
            marginTop: 10,
            marginRight: 5,
            cursor: 'pointer'
        },
        ReplyInput: {
            marginLeft: 40,
            borderRadius: 10,
            padding: 10,
            height: 30,
            width: '88%',
            border: 'solid 1px grey'
        },
        replyIcon: {
            fontSize: 14
        },
        commentPopUp: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px grey',
            borderRadius: 5,
            fontSize: 12,
            width: 400,
            color: 'grey',
            fontWeight: 700,
            marginLeft: 40,
            marginBottom: 10
        },
        selectUserItem: {
            cursor: 'pointer',
            listStyleType: 'square'
        }
    }

    async function getUsers() {
        const response = await SendGet(`api/v1/users`);
        if( !isEmpty(response.data) )
        {
            setUsers(response.data);
        }
    }

    function handleInputComment(e) {

        setCommentText(e.target.value);

        const value = e.target.value;

        const parts = value.split(/(\s+)/);
        const lastPart = parts[parts.length - 1];
        if (lastPart.includes('@')) {
            setShowPopUp(true);
            const searchTerm = lastPart.split('@')[1];
            setFilteredUsers(users.filter(item => (`${item.lastName}${item.firstName}`).toLowerCase().includes(searchTerm.toLowerCase())));
        } else {
            setShowPopUp(false);
        }

    }

    function handleUserSelect(user) {
        const parts = commentText.split('@');
        parts[parts.length - 1] = (`${user.lastName}_${user.firstName}`).replace(' ', '_') + ' ';
        setCommentText(parts.join('@'));
        setShowPopUp(false);
    }

    async function createReplyToComment() {

        if( isEmpty(commentText) )
        {
            return;
        }

        const response = await SendPost(`api/v1/comments`, {
            id: 0,
            commentText: commentText,
            fullName: `${connectedUser.lastName} ${connectedUser.firstName}`,
            credate: Date.now(),
            creatorId: connectedUser.id,
            replyTo: parseInt(id),
            riskId: parseInt(riskId)
        });

        // Send a notification to the comment owner
        await SendPost(`api/v1/notifications`, {
            userId: parseInt(creatorId),
            notificationType: "information",
            objectType: "risk",
            objectId: parseInt(riskId),
            content: `${connectedUser.firstName} ${connectedUser.lastName} a répondu à votre commentaire.`,
            opened: 0,
            creatorId: parseInt(connectedUser.id),
            credate: Math.floor(Date.now() / 1000)
        });

        if( response.status === 200 )
        {
            await getComments();
            setCommentText("");
        }
    }

    function onKeyDownHandler(e) {
        if (e.keyCode === 13) {
            createReplyToComment();
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div style={styles.container}>
            <span style={styles.nameLabel}>{fullname}</span> <span style={styles.dateLabel}>{convertDateToString(credate)}</span> <span style={styles.replyBtn} onClick={() => setShowCommentInput(!showCommentInput)}>Reply <span style={styles.replyIcon} className="material-symbols-outlined">reply</span> </span>
            <p style={styles.commentLabel}>{comment}</p>
            {
                !isEmpty(replies) && replies.map((item, index) => {
                    return (
                        <div style={{ ...styles.container, marginLeft: 40 }} key={index}>
                            <span style={styles.nameLabel}>{item.fullName}</span> <span style={styles.dateLabel}>{convertDateToString(item.credate)}</span>
                            <p style={styles.commentLabel}>{item.commentText}</p>
                        </div>
                    )
                })
            }
            {
                showPopUp &&
                <div style={styles.commentPopUp}>
                    <ul style={{ textDecoration: 'none' }}>
                        {
                            filteredUsers.map((item, index) => {
                                return (
                                    <li style={styles.selectUserItem} key={index} onClick={() => handleUserSelect(item)}>{`${item.lastName} ${item.firstName}`}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
            { showCommentInput && <><textarea onKeyDown={onKeyDownHandler} style={styles.ReplyInput} onChange={handleInputComment} value={commentText} placeholder="Write your comment here ..." /> <span style={styles.commentBtn} onClick={() => createReplyToComment()}>Comment</span></> }
        </div>
    );
}
