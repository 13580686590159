import graviteImg from "../assets/images/chart1.png";
import maitriseImg from "../assets/images/chart2.png";
import {useEffect, useState} from "react";
import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {getColorHexCode, isEmpty, SendGet} from "../State/Helper";
import CircleMenu from "../Components/CircleMenu";
import {useAuth} from "../State/useAuth";
import { primaryColor } from "../configuration/colorPalet";
import NotificationBadge from "../Components/NotificationBadge";
import RiskMatriceChart from "../Components/RiskMatriceChart";
import RiskExpositionChart from "../Components/RiskExpositionChart";

export default function Graphics() {

    const [showSideMenu, setShowSideMenu] = useState(true);
    const [showMaitrise, setShowMaitrise] = useState(false);
    const [showMatrice, setShowMatrice] = useState(false);

    const styles = {
        container: {
            display: 'flex',
            flex: 6,
            flexDirection: 'column',
            padding: 20,
            backgroundColor: '#FFFFFF'
        },
        sectionContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            cursor: 'pointer'
        },
        sectionTitle: {
            fontSize: 16,
            fontWeight: 700,
            marginTop: 35,
            color: primaryColor,
            flex: 2
        },
        sectionImage: {
            height: 50,
            width: 80,
            padding: 20
        },
        sectionIcon: {
            color: primaryColor,
            fontSize: 30,
            fontWeight: 700,
            verticalAlign: 'middle',
            marginTop: 30,
            marginRight: 10
        },
        mainContainer: {
            backgroundColor: '#FFFFFF',
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer'
        },
        chartContainer: {
            borderTop: 'none',
            borderRight: 'none',
            background: `linear-gradient(to top right, ${ getColorHexCode(1) }, ${ getColorHexCode(2) }, ${ getColorHexCode(3) }, ${ getColorHexCode(4) })`,
            width: `900px`,
            height: `600px`,
            border: '1px solid black',
            position: 'relative',
            borderLeft: 'solid 5px #000000',
            marginTop: 50
        },
        chartRow: {
            height: 50,
            display: 'flex',
            flexDirection: 'row',
            flex: 0.8,
        },
        chartColumn: {
            flex: 0.8,
            border: 'solid 0.2px #C9A457'
        },
        verticalNumbering: {
            flex: 0.8,
            color: '#000000',
            fontWeight: 600
        },
        horizontalNumbering: {
        },
        chartCircle: {
            backgroundColor: '#FFFFFF', width: 80, height: 80, borderRadius: '50%', zIndex: 9999
        }
    }

    return (

        <>
            <RiskTopMenu setShowSideMenu={() => setShowSideMenu(!showSideMenu)} showSideMenu={showSideMenu} />
            <div style={{ display: 'flex' }}>
                <RiskSideMenu showSideMenu={showSideMenu} selectedTab="graphic"  />
                <div style={styles.container}>

                    <NotificationBadge />

                    <div style={styles.sectionContainer} onClick={() => setShowMaitrise(!showMaitrise)}>
                        <img style={styles.sectionImage} alt="image" src={graviteImg} />
                        <p style={styles.sectionTitle}>Matrice d'évaluation de la criticité des risques bruts</p>
                        { showMaitrise === false && <span className="material-symbols-outlined" style={styles.sectionIcon}>expand_more</span> }
                        { showMaitrise === true && <span className="material-symbols-outlined" style={styles.sectionIcon}>navigate_next</span> }
                    </div>

                    { !showMaitrise && <RiskMatriceChart /> }

                    <div style={{ ...styles.sectionContainer, marginTop: 20 }} onClick={() => setShowMatrice(!showMatrice)}>
                        <img style={styles.sectionImage} alt="image" src={maitriseImg} />
                        <p style={styles.sectionTitle}>Matrice présentant le degré d'exposition aux risques</p>
                        { showMatrice === false && <span className="material-symbols-outlined" style={styles.sectionIcon}>navigate_next</span> }
                        { showMatrice === true && <span className="material-symbols-outlined" style={styles.sectionIcon}>expand_more</span> }
                    </div>

                    { showMatrice && <RiskExpositionChart /> }

                    </div>

                <CircleMenu  />

            </div>
        </>
    );
}
