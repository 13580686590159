import React, { useState, useEffect } from "react";
import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {isEmpty, SendGet} from "../State/Helper";
import { useAuth } from "../State/useAuth";
import Dashboard from "./Dashboard";
import CircleMenu from "../Components/CircleMenu";
import ListPaginator from "../Components/ListPaginator";
import NotificationBadge from "../Components/NotificationBadge";

export default function List() {

    const [showSideMenu, setShowSideMenu] = useState(true);
    const [riskList, setRiskList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {processus, entite, objectif, searchKeyword, rankFilter} = useAuth();

    const styles = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 20
        },
        bodyContainer: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: '#FFFFFF'
        },
        cardRow: {
            display: "flex",
            flexDirection: "row",
        },
    };

    // Get risks list
    async function getRiskList() {

        let response = await SendGet(`api/v1/risks/currentuser`);

        if ( !isEmpty(response.data) ) {

            let parsedProcessus = processus.value !== null ? parseInt(processus.value) : null;
            let parsedEntite = entite.value !== null ? parseInt(entite.value) : null;
            let parsedObjectif = objectif.value !== null ? parseInt(objectif.value) : null;
            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let risks = response.data.filter(item =>
                (parsedProcessus === null || parseInt(item.processusId) === parsedProcessus) &&
                (parsedEntite === null || parseInt(item.entiteId) === parsedEntite) &&
                (parsedObjectif === null || parseInt(item.objectifId) === parsedObjectif) &&
                (parseSearchKeyword === null || item.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()))
            );

            let assessedRisks = [];

            await Promise.all(risks.map(async risk => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            // Remove the none evaluated ones
            let nonEvaluatedRisks = assessedRisks.filter(item => isEmpty(item.calcs));

            let evaluatedRisks = assessedRisks.filter(item => !isEmpty(item.calcs));

            // Sort the array by recently created
            evaluatedRisks = evaluatedRisks.sort((a, b) => {
                return b.calcs.criticite - a.calcs.criticite;
            });

            assessedRisks = [...evaluatedRisks, ...nonEvaluatedRisks];

            // Filter by the risk rank
            if( rankFilter !== null && rankFilter !== "not_evaluated" )
            {
                const filtered = [];

                // Loop through all evaluated
                evaluatedRisks.map(item => {

                    if( Number(rankFilter) === 4 )
                    {
                        if( Number(item.calcs.criticite) === 4 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 3 )
                    {
                        if( Number(item.calcs.criticite) === 3 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 2 )
                    {
                        if( Number(item.calcs.criticite) === 2 )
                        {
                            filtered.push(item);
                        }
                    }
                    else if( Number(rankFilter) === 1 ) {
                        
                        if( Number(item.calcs.criticite) === 1 ) {
                            filtered.push(item);
                        }
                    }

                });

                setRiskList(filtered);
            }
            else if( rankFilter === null )
            {
                setRiskList(assessedRisks);
            }
            else if( rankFilter === "not_evaluated" )
            {
                setRiskList(nonEvaluatedRisks);
            }
        }
        else
        {
            setRiskList([]);
        }
    }

    async function getAssessment(riskId) {

        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessments) )
        {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        }
        else
        {
            return null;
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                await getRiskList();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [processus, entite, objectif, searchKeyword, rankFilter]);

    if( processus.value == null )
    {
        return (
            <Dashboard />
        );
    }
    else
    {
        return (
            <div >
                <RiskTopMenu setShowSideMenu={() => setShowSideMenu(!showSideMenu)} showSideMenu={showSideMenu} />
                <div style={styles.bodyContainer}>
                    <div>
                        <RiskSideMenu showSideMenu={showSideMenu} selectedTab="list" />
                    </div>
                    { !isEmpty(riskList) && <ListPaginator items={riskList} itemsPerPage={15} /> }
                    <CircleMenu />
                </div>
            </div>
        );
    }
};
