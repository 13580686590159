import RiskSideMenu from "../Components/RiskSideMenu";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import RiskTopMenu from "../Components/RiskTopMenu";
import {isEmpty, SendGet, SendPost, SendPut} from "../State/Helper";

export default function CreateProcessus() {

    const navigate = useNavigate();
    const [label, setLabel] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState(null);
    const { id } = useParams();

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            margin: 40,
            padding: 20
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        iconStyle: {
            fontSize: 23,
            verticalAlign: 'middle'
        },
        requiredLabel: {
            color: '#D8111C'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        }
    }

    // Edit mode
    useEffect(() => {
        async function fetchData() {
            if(!isEmpty(id))
            {
                const response = await SendGet(`api/v1/processus/${id}`);
                if(response.data)
                {
                    setLabel(response.data.label);
                    setIsEditMode(true);
                }
            }
        }
        fetchData().catch(e => console.log(`Erreur!`));
    }, []);

    async function createProcessus() {

        if( isEmpty( label ) ) {

            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        await SendPost('api/v1/processus', {
            label: label
        });

        navigate("/processus");
    }

    const updateProcessus = async () => {

        // Check the inputs for value before sending the request
        if( label === '' )
        {
            setError('Veuillez remplir tous les champs obligatoires');
            return false;
        }

        // Send the request to the backend in order to persist the data
        try
        {
            await SendPut(`api/v1/processus/${id}`, {
                label: label
            });

            navigate(`/processus`);
        }
        catch(error)
        {
            setError('Erreur!');
        }
    }

    return (
        <div style={styles.container}>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div style={styles.inputRow}>
                        <span style={styles.colLabel}> Libellé <span style={{ color: 'red' }}>*</span></span>
                        <input value={label} type="text" style={styles.inputField} onChange={e => setLabel(e.target.value)} />
                    </div>
                    <div style={{ textAlign: 'center', margin: 20 }}>
                        <div style={{ flex: 3, textAlign: 'center' }}>
                            <span onClick={() => navigate('/processus')} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Annuler</span>
                            { !isEditMode && <span onClick={() => createProcessus()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</span> }
                            { isEditMode && <span style={styles.createBtn} onClick={() => updateProcessus()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
