import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Grid from "./screens/Grid";
import About from "./screens/About";
import ErrorPage from "./screens/Error";
import Login from "./screens/Login";
import RiskDetail from "./screens/RiskDetail";
import Graphics from "./screens/Graphics";
import RiskCreate from "./screens/RiskCreate";
import {AuthProvider} from "./State/AuthProvider";
import ProtectedRoute from "./State/ProtectedRoute";
import PublicRoute from "./State/PublicRoute";
import List from "./screens/List";
import Users from "./screens/Users";
import UserCreate from "./screens/UserCreate";
import Statistics from "./screens/Statistics";
import Profile from "./screens/Profile";
import Dashboard from "./screens/Dashboard";
import Activity from "./screens/Activity";
import CreateActivity from "./screens/CreateActivity";
import Objectifs from "./screens/Objectifs";
import CreateObjectif from "./screens/CreateObjectif";
import CreateCompany from "./screens/CreateCompany";
import BusinessUnit from "./screens/BusinessUnit";
import CreateBu from "./screens/CreateBu";
import BusinessActivity from "./screens/BusinessActivity";
import CreateBa from "./screens/CreateBa";
import CreateCompanyBA from "./screens/CreateCompanyBA";
import LegalEntities from "./screens/LegalEntities";
import Processus from "./screens/Processus";
import CreateRiskAssessment from "./screens/CreateRiskAssessment";
import RiskAssessments from "./screens/RiskAssessments";
import CreateCategory from './screens/CreateCategory';
import CategoriesList from './screens/CategoriesList';
import CreateAssessment from "./screens/CreateAssessment";
import CreateAction from './screens/CreateAction';
import ActionsPlan from './screens/ActionsPlan';
import ListSmtp from './screens/ListSmtp';
import CreateSmtp from './screens/CreateSmtp';
import TemplateList from './screens/TemplateList';
import CreateTemplate from './screens/CreateTemplate';
import Denied from "./screens/Denied";
import GeneralSettings from './screens/GeneralSettings';
import NotificationScreen from './screens/NotificationScreen';
import CreateBuBa from './screens/CreateBuBa';
import BuBas from './screens/BuBas';
import Entite from "./screens/Entite";
import CreateEntitie from "./screens/CreateCompany";
import CreateProcessus from "./screens/CreateProcessus";
import ConsequencePotentiel from "./screens/ConsequencePotentiel";
import CreateConsequencePotentiel from "./screens/CreateConsequencePotentiel";

function App() {

    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/dashboard" element={<ProtectedRoute right="READ" ><Dashboard /></ProtectedRoute>} />
                    <Route path="/objectifs" element={<ProtectedRoute requireAdmin={true}><Objectifs /></ProtectedRoute>} />
                    <Route path="/objectifs/create" element={<ProtectedRoute requireAdmin={true}><CreateObjectif /></ProtectedRoute>} />
                    <Route path="/objectifs/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateObjectif /></ProtectedRoute>} />
                    <Route path="/legal-entities" element={<ProtectedRoute right="READ" ><LegalEntities/></ProtectedRoute>} />
                    <Route path="/entites" element={<ProtectedRoute requireAdmin={true}><Entite /></ProtectedRoute>} />
                    <Route path="/bubas" element={<ProtectedRoute requireAdmin={true}><BuBas /></ProtectedRoute>} />
                    <Route path="/bus" element={<ProtectedRoute requireAdmin={true}><BusinessUnit /></ProtectedRoute>} />
                    <Route path="/bas" element={<ProtectedRoute requireAdmin={true}><BusinessActivity /></ProtectedRoute>} />
                    <Route path="/CompanyBA/create" element={<ProtectedRoute requireAdmin={true}><CreateCompanyBA /></ProtectedRoute>} />
                    <Route path="/buba/create" element={<ProtectedRoute requireAdmin={true}><CreateBuBa /></ProtectedRoute>} />
                    <Route path="/bus/create" element={<ProtectedRoute requireAdmin={true}><CreateBu /></ProtectedRoute>} />
                    <Route path="/bas/create" element={<ProtectedRoute requireAdmin={true}><CreateBa /></ProtectedRoute>} />
                    <Route path="/entites/create" element={<ProtectedRoute requireAdmin={true}><CreateEntitie /></ProtectedRoute>} />
                    <Route path="/entites/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateEntitie /></ProtectedRoute>} />
                    <Route path="/CompanyBA/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateCompanyBA /></ProtectedRoute>} />
                    <Route path="/buba/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateBuBa /></ProtectedRoute>} />
                    <Route path="/bus/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateBu /></ProtectedRoute>} />
                    <Route path="/bas/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateBa /></ProtectedRoute>} />
                    <Route path="/entites/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateCompany /></ProtectedRoute>} />
                    <Route path="/processus" element={<ProtectedRoute right="READ" ><Processus /></ProtectedRoute>} />
                    <Route path="/processus/create" element={<ProtectedRoute right="CREATE" ><CreateProcessus /></ProtectedRoute>} />
                    <Route path="/processus/edit/:id" element={<ProtectedRoute right="EDIT" ><CreateProcessus /></ProtectedRoute>} />
                    <Route path="/consequence-potentiel" element={<ProtectedRoute right="READ" ><ConsequencePotentiel /></ProtectedRoute>} />
                    <Route path="/consequence-potentiel/create" element={<ProtectedRoute right="READ" ><CreateConsequencePotentiel /></ProtectedRoute>} />
                    <Route path="/consequence-potentiel/edit/:id" element={<ProtectedRoute right="READ" ><CreateConsequencePotentiel /></ProtectedRoute>} />
                    <Route path="/statistics" element={<ProtectedRoute right="READ" ><Statistics /></ProtectedRoute>} />
                    <Route path="/activities" element={<ProtectedRoute right="READ" ><Activity /></ProtectedRoute>} />
                    <Route path="/activities/create" element={<ProtectedRoute right="CREATE" ><CreateActivity /></ProtectedRoute>} />
                    <Route path="/activities/edit/:id" element={<ProtectedRoute right="EDIT" ><CreateActivity /></ProtectedRoute>} />
                    <Route path="/users" element={<ProtectedRoute requireAdmin={true}><Users /></ProtectedRoute>} />
                    <Route path="/users/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                    <Route path="/users/create" element={<ProtectedRoute requireAdmin={true}><UserCreate /></ProtectedRoute>} />
                    <Route path="/users/edit/:id" element={<ProtectedRoute requireAdmin={true}><UserCreate /></ProtectedRoute>} />
                    <Route path="/login" element={<PublicRoute redirectTo={"/list"}><Login /></PublicRoute>} />
                    <Route path="/about" element={<About />} />
                    <Route path="/risk/create" element={<ProtectedRoute right="CREATE"><RiskCreate /></ProtectedRoute>} />
                    <Route path="/risks/edit/:id" element={<ProtectedRoute right="EDIT"><RiskCreate /></ProtectedRoute>} />
                    <Route path="/graphics" element={<ProtectedRoute right="READ"><Graphics /></ProtectedRoute>} />
                    <Route path="/list" element={<ProtectedRoute><List right="READ" /></ProtectedRoute>} />
                    <Route path="/risks/detail/:id" element={<ProtectedRoute right="READ"><RiskDetail /></ProtectedRoute>} />
                    <Route path="/" element={<ProtectedRoute><List right="READ" /></ProtectedRoute>} />
                    <Route path="/grid" element={<ProtectedRoute right="READ" ><Grid /></ProtectedRoute>} />
                    <Route path="/*" element={<ErrorPage />} />
                    <Route path="/riskassessment/create" element={<ProtectedRoute requireAdmin={true}><CreateRiskAssessment/></ProtectedRoute>} />
                    <Route path="/riskassessment/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateRiskAssessment/></ProtectedRoute>} />
                    <Route path="/riskassessments" element={<ProtectedRoute requireAdmin={true}><RiskAssessments /></ProtectedRoute>} />
                    <Route path="/category/create" element={<ProtectedRoute requireAdmin={true} ><CreateCategory /></ProtectedRoute>} />
                    <Route path="/category/edit/:id" element={<ProtectedRoute requireAdmin={true}  ><CreateCategory /></ProtectedRoute>} />
                    <Route path="/categories" element={<ProtectedRoute right="READ" ><CategoriesList /></ProtectedRoute>} />
                    <Route path="/assessments/create/:riskId" element={<ProtectedRoute right="EVALUATE"><CreateAssessment /></ProtectedRoute>} />
                    <Route path="/action/create/:idRisk" element={<ProtectedRoute right="CREATE" ><CreateAction/></ProtectedRoute>} />
                    <Route path="/action/edit/:idRisk/:actionId" element={<ProtectedRoute right="EDIT" ><CreateAction/></ProtectedRoute>} />
                    <Route path="/actionsplan" element={<ProtectedRoute><ActionsPlan right="READ" /></ProtectedRoute>} />
                    <Route path="/smtps" element={<ProtectedRoute requireAdmin={true}><ListSmtp /></ProtectedRoute>} />
                    <Route path="/smtps/create" element={<ProtectedRoute requireAdmin={true}><CreateSmtp /></ProtectedRoute>} />
                    <Route path="/smtps/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateSmtp /></ProtectedRoute>} />
                    <Route path="/templates" element={<ProtectedRoute requireAdmin={true}><TemplateList /></ProtectedRoute>} />
                    <Route path="/templates/create" element={<ProtectedRoute requireAdmin={true}><CreateTemplate /></ProtectedRoute>} />
                    <Route path="/templates/edit/:id" element={<ProtectedRoute requireAdmin={true}><CreateTemplate /></ProtectedRoute>} />
                    <Route path="/general_settings/:id" element={<ProtectedRoute requireAdmin={true}><GeneralSettings /></ProtectedRoute>} />
                    <Route path="/notifications" element={<ProtectedRoute requireAdmin={false}><NotificationScreen /></ProtectedRoute>} />
                    <Route path="/denied" element={<Denied />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
