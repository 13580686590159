import { useEffect, useState } from "react";
import RiskCardList from "./RiskCardList";
import { SendGet, isEmpty } from "../State/Helper";
import { useAuth } from "../State/useAuth";
import { primaryColor } from "../configuration/colorPalet";

export default function MappingContainer({ processus }) {

    const [riskList, setRiskList] = useState([]);

    const [lowRiskCounter, setLowRiskCounter] = useState(0);
    const [safeRiskCounter, setSafeRiskCounter] = useState(0);
    const [mediumRiskCounter, setMediumRiskCounter] = useState(0);
    const [criticalRiskCounter, setCriticalRiskCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const [expended, setExpended] = useState(false);
    const {searchKeyword} = useAuth();

    // Setting counters
    const fetchRiskData = async () => {

        let lowCounter = 0;
        let safeCounter = 0;
        let mediumCounter = 0;
        let criticalCounter = 0;

        try {

            // Get the risks available for the current user only
            const riskResponse = await SendGet(`api/v1/risks/currentuser`);

            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let filteredRisks = riskResponse.data;

            // Filter risks based on the keyword
            if( parseSearchKeyword !== null )
            {
                filteredRisks = riskResponse.data.filter(risk => risk.frTitle.includes(parseSearchKeyword))
            }

            // Check if the response is empty
            if( !isEmpty(filteredRisks) )
            {
                // Filter the risks to get only the ones for that specific processus
                filteredRisks = filteredRisks.filter(risk => parseInt(risk.processusId) === parseInt(processus.id));

                // Loop through all the risks
                for (let item of filteredRisks)
                {
                    // Get the assessment for the risk
                    let assessmentResponse = await SendGet(`api/v1/assessments/risk/${item.id}`);

                    // Check if it's evaluated or not
                    if( !isEmpty(assessmentResponse.data) )
                    {
                        // Get active assessment
                        let assessment = assessmentResponse.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                        if( !isEmpty(assessment) )
                        {
                            let criticite = Number( assessment.criticite );

                            if (criticite === 4) {
                                criticalCounter += 1;
                            } else if (criticite === 3) {
                                mediumCounter += 1;
                            } else if (criticite === 2) {
                                safeCounter += 1;
                            } else if (criticite === 1) {
                                lowCounter += 1;
                            }
                        }
                    }
                }
            }

            setLowRiskCounter(lowCounter);
            setSafeRiskCounter(safeCounter);
            setMediumRiskCounter(mediumCounter);
            setCriticalRiskCounter(criticalCounter);

        } catch (error)
        {
            console.error("An error occurred while fetching risk data:", error);
        }
    };

    useEffect(() => {
        async function fetchData()
        {
            try {
                setLoading(true);
                await fetchRiskData();
                await getRiskList()
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [processus, expended, searchKeyword]);

    // Get the actual risks data
    async function getRiskList() {

        // Get the risks for the current active user
        let response = await SendGet(`api/v1/risks/currentuser`);

        let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

        // Filter risks based on the keyword
        let filteredRisks = response.data;

        if( parseSearchKeyword !== null )
        {
            filteredRisks = response.data.filter(risk => ( risk.enTitle.includes(parseSearchKeyword) || risk.frTitle.includes(parseSearchKeyword) ));
        }

        let assessedRisks = [];

        // Check if the response has some data
        if ( !isEmpty(filteredRisks) && expended !== false ) {

            await Promise.all(filteredRisks.map(async risk => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            // Filter the risks based on processus
            assessedRisks = assessedRisks.filter(risk => parseInt(risk.processusId) === parseInt(processus.id));

            // Filter evaluated
            assessedRisks = assessedRisks.filter(risk => !isEmpty(risk.calcs));

            // Sort the array
            assessedRisks = assessedRisks.sort((a, b) => a.credate - b.credate);

            let expandFiltered = [];

            // filter by the type
            if( parseInt(expended) === 1 )
            {
                expandFiltered = assessedRisks.filter(item => Number( item.calcs.criticite ) === 1);

            } else if ( parseInt(expended) === 2 )
            {
                expandFiltered = assessedRisks.filter(item => Number( item.calcs.criticite ) === 2 );

            } else if ( parseInt(expended) === 3 )
            {
                expandFiltered = assessedRisks.filter(item => Number( item.calcs.criticite ) === 3 );

            } else if ( parseInt(expended) === 4 ) 
            {
                expandFiltered = assessedRisks.filter(item => Number( item.calcs.criticite ) === 4 );
            }

            console.log('Filtered: ', expandFiltered);

            setRiskList(expandFiltered);
        }
    }

    async function getAssessment(riskId) {

        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessments) )
        {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        }
        else
        {
            return null;
        }
    }

    function handleClick(id) {

        if( parseInt(expended) !== parseInt(id) )
        {
            setExpended(id);
        }
        else
        {
            setExpended(false);
            setRiskList([]);
        }
    }

    const styles = {
        riskContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            padding: 10,
            paddingLeft: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            marginBottom: 20
        },
        label: {
            fontSize: 12,
            color: primaryColor,
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        iconStyle: {
            fontSize: 16,
            verticalAlign: 'middle',
            fontWeight: 700
        },
        cartographieDetails: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 20
        }
    }

    return (
        <div style={styles.riskContainer} >

            <div style={{ marginBottom: 10, margin: 10 }}>
                <span style={styles.label}>{processus.label}</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                <div onClick={() => handleClick(1)} style={{ padding: 10, flex: 1.5, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, backgroundColor: '#F4B084' }}>
                    { expended !== 1 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{lowRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 1 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{lowRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
                <div onClick={() => handleClick(2)} style={{ padding: 10, flex: 1.5, backgroundColor: '#CEC240' }}>
                    { expended !== 2 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{safeRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 2 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{safeRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
                <div onClick={() => handleClick(3)} style={{ padding: 10, flex: 1.5, backgroundColor: '#E68A00' }}>
                    { expended !== 3 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{mediumRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 3 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{mediumRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
                <div onClick={() => handleClick(4)} style={{ padding: 10, flex: 1.5, borderTopRightRadius: 20, borderBottomRightRadius: 20, backgroundColor: '#D64C47' }}>
                    { expended !== 4 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{criticalRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 4 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{criticalRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
            </div>

            { loading && <div style={{ textAlign: 'center' }}><img src="/loading.gif" width={50} style={{alignSelf: 'center', marginTop: 50}}/></div>}

            {
                expended &&
                <div style={styles.cartographieDetails}>
                    {
                        !isEmpty(riskList) && riskList.map((item, index) => {

                            return (
                                <RiskCardList key={index} frTitle={item.frTitle} risk={item} />
                            )
                        })
                    }
                </div>
            }

        </div>
    );
}
