import {SendGet} from "../State/Helper";

export const calculateRisk = async ( impact, maitrise, occurrence ) => {

    // Convert to integers
    impact = Number(impact);
    maitrise = Number(maitrise);
    occurrence = Number(occurrence);

    // Get the active formula
    let rsp = await SendGet(`api/v1/riskassessment`);

    // Check in case of no formula found
    if( rsp.data === undefined || !Array.isArray(rsp.data) )
    {
        return 0;
    }

    // Get the active formula based with the biggest id number which means the formula with higher id number is the one used
    let formula = rsp.data.reduce( (max, obj) => parseInt(obj.id) > parseInt(max.id) ? obj : max );

    // Calculate maitrise percentage
    let maitrisePercentage = Number( parseFloat((maitrise / 4) * 100) );

    // The dynamic part of the formula on scale 4
    let riskBrute = eval(formula.grossRiskFormulaBase6.replace(/:OC/g, Number( parseFloat(occurrence).toFixed(2) )).replace(/:IM/g, Number( parseFloat(impact).toFixed(2) ))); // RiskBrut = Occurrence * Impact
    let riskBruteNormalise = Number(( riskBrute / 16 ) * 4);

    let riskNet = eval(formula.netRiskFormulaBase6.replace(/:SB/g, Number( parseFloat(riskBruteNormalise).toFixed(2) )).replace(/:MP/g, Number( parseFloat(maitrise).toFixed(2) ))); // RiskNet = RiskBrut - maitrise
    let riskNetNormalise = Number( riskNet );

    let marginProgres = Number( riskBruteNormalise - riskNetNormalise );
    let marginProgresNormalise = Number( riskBruteNormalise - riskNetNormalise );
    let marginProgresPercentage = Number( ( marginProgres / 4 ) * 100 );
    let marginProgresPercentageNormalise = Number( ( marginProgres / 4 ) * 100 );

    return {
        riskBrute,
        riskNet,
        riskBruteNormalise,
        riskNetNormalise,
        impact,
        maitrise,
        maitrisePercentage,
        marginProgres,
        marginProgresNormalise,
        marginProgresPercentage,
        marginProgresPercentageNormalise
    };
};

export const calculateRiskBrute = ( impact, occurrence, maitrise ) => {

    return Number(impact) * Number(occurrence) * ( 100 - Number(maitrise) );
}

export const getProcessus = async ( processusId ) => {
    const response = await SendGet(`api/v1/processus/${processusId}`);
    if( response.data ) {
        return response.data;
    } else {
        return null;
    }
}

export const getEntite = async ( entiteId ) => {
    const response = await SendGet(`api/v1/entite/${entiteId}`);
    if( response.data ) {
        return response.data;
    } else {
        return null;
    }
}

export const getObjectif = async ( objectifId ) => {
    const response = await SendGet(`api/v1/objectif/${objectifId}`);
    if( response.data ) {
        return response.data;
    } else {
        return null;
    }
}

export const getProcessusList = async () => {
    const response = await SendGet(`api/v1/processus/all`);
    if( response.data ) {
        return response.data;
    } else {
        return [];
    }
}

export const getEntiteList = async () => {
    const response = await SendGet(`api/v1/entite/all`);
    if( response.data ) {
        return response.data;
    } else {
        return [];
    }
}

export const getObjectifList = async () => {
    const response = await SendGet(`api/v1/objectif/all`);
    if( response.data ) {
        return response.data;
    } else {
        return [];
    }
}

// Calculate criticité
export function calculateCriticite (gravity, occurrence) {

    const product = gravity * occurrence;

    if (product >= 16 && product <= 25) {
        return 4;
    } else if (product >= 9 && product <= 15) {
        return 3;
    } else if (product >= 3 && product <= 8) {
        return 2;
    } else if (product >= 1 && product <= 2) {
        return 1;
    }

    return null;
}

// Calculate exposition
export function getExposition(criticite, maitrise) {
    if (maitrise === "") {
        return null;
    }

    if (criticite <= 2) {
        return maitrise <= 2 ? 1 : 2;
    } else {
        return maitrise > 2 ? 3 : 4;
    }
}

export function criticiteToText(criticite) {
    switch ( Number(criticite) ) {
        case 1:
            return "Criticité très faible";
            break;
        case 2:
            return "Criticité plutot faible";
            break;
        case 3:
            return "Criticité pultot élevée";
            break;
        case 4:
            return "Criticité très élevée";
            break;
        default:
            return "";
            break;
    }
}

export function expositionToText(exposition) {
    switch ( Number(exposition) ) {
        case 1:
            return "Veille";
        case 2:
            return "Surinvestissement";
        case 3:
            return "Vigilance";
        case 4:
            return "Prioritaire";
        default:
            return null; // or an appropriate default value or error message
    }
}