import {useEffect, useState} from "react";
import Success from "./Success";
import Error from "./Error";
import Comment from "./Comment";
import {getConnectedUser, isEmpty, SendGet, SendPost} from "../State/Helper";

export default function CommentsComponent({ riskId }) {


    const [comments, setComments] = useState([]);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [disabledInput, setDisabledInput] = useState("");
    const connectedUser = getConnectedUser();

    async function getComments() {
        const response = await SendGet(`api/v1/comments/parent/${riskId}`);
        const data = [];

        if (!isEmpty(response.data)) {
            // Use map to create an array of promises for each comment's replies
            const promises = response.data.map(async (comment) => {
                const info = await SendGet(`api/v1/comments/replyto/${comment.id}`);
                return { ...comment, replies: info.data };
            });

            // Await all promises and then set the comments
            const commentsWithReplies = await Promise.all(promises);
            setComments(commentsWithReplies);
        } else {
            // If no comments, set data to an empty array or handle as needed
            setComments(data);
        }
    }

    async function getUsers() {
        const response = await SendGet(`api/v1/users`);
        if( !isEmpty(response.data) )
        {
            setUsers(response.data);
        }
    }

    async function createComment() {

        if( isEmpty(commentText) )
        {
            setError(`Veuillez remplir tous les champs obligatoires.`);
            return;
        }

        // Disable comment box
        setDisabledInput("disabled");

        // Insert the comment
        const commentResponse = await SendPost(`api/v1/comments`, {
            id: 0,
            commentText: commentText,
            fullName: `${connectedUser.lastName} ${connectedUser.firstName}`,
            credate: Date.now(),
            creatorId: connectedUser.id,
            riskId: riskId
        });

        const mentionedUsers = [];

        // Check if the comment contains some mentioned users
        users.forEach(function(user) {

            const fullName = (`${user.lastName}_${user.firstName}`).replace(' ', '_');

            if ( commentText.toLowerCase().includes(fullName.toLowerCase()) )
            {
                mentionedUsers.push(user);
            }
        });

        // Insert notifications for the mentioned users
        await Promise.all(mentionedUsers.map(async function(user) {
            await SendPost(`api/v1/notifications`, {
                userId: parseInt(user.id),
                notificationType: "information",
                objectType: "risk",
                objectId: parseInt(riskId),
                content: "Votre nom est mentionné dans un nouveau commentaire!",
                opened: 0,
                creatorId: parseInt(connectedUser.id),
                credate: Math.floor(Date.now() / 1000)
            });
        }));

        if( commentResponse.status === 200 )
        {
            await getComments();
            setCommentText("");
        }
        else
        {
            setError(`Erreur!`);
        }

        // Enable comment input
        setDisabledInput("");
    }

    function handleInputComment(e) {

        setCommentText(e.target.value);

        const value = e.target.value;

        const parts = value.split(/(\s+)/);
        const lastPart = parts[parts.length - 1];
        if (lastPart.includes('@')) {
            setShowPopUp(true);
            const searchTerm = lastPart.split('@')[1];
            setFilteredUsers(users.filter(item => (`${item.lastName}${item.firstName}`).toLowerCase().includes(searchTerm.toLowerCase())));
        } else {
            setShowPopUp(false);
        }

    }

    function handleUserSelect(user) {
        const parts = commentText.split('@');
        parts[parts.length - 1] = (`${user.lastName}_${user.firstName}`).replace(' ', '_') + ' ';
        setCommentText(parts.join('@'));
        setShowPopUp(false);
    }

    useEffect(() => {
        async function fetchData() {
            await getComments();
            await getUsers();
        }
        fetchData();
    }, []);

    function onKeyDownHandler(e) {
        if (e.keyCode === 13) {
            createComment();
        }
    };

    const styles = {
        mainContainer: {
            marginTop: 10
        },
        commentBtn: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            border: 'solid 1px #000000',
            borderRadius: 3,
            fontSize: 12,
            padding: 6,
            float: 'right',
            fontWeight: 700,
            marginTop: 20,
            marginRight: 5,
            cursor: 'pointer',
        },
        ReplyInput: {
            borderRadius: 5,
            padding: 10,
            height: 30,
            width: '92%',
            border: 'solid 1px #767676',
            marginBottom: 10,
            marginTop: 10
        },
        commentPopUp: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #767676',
            borderRadius: 5,
            fontSize: 12,
            width: 400,
            color: '#000000',
            fontWeight: 700
        },
        selectUserItem: {
            cursor: 'pointer',
            listStyleType: 'square',
            padding: 5,
            paddingLeft: 15
        },
        lineSeperator: {
            marginLeft: -30,
            marginRight: 10
        }
    }

    return (
        <>
            <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
            <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />
            <div style={styles.mainContainer}>
                {
                    showPopUp &&
                    <div style={styles.commentPopUp}>
                        {
                            filteredUsers.map((item, index) => {
                                return (
                                    <p style={styles.selectUserItem} className="btn-primary-custom" key={index} onClick={() => handleUserSelect(item)}>{`${item.lastName} ${item.firstName}`}</p>
                                )
                            })
                        }
                    </div>
                }
                <textarea onKeyDown={onKeyDownHandler} style={styles.ReplyInput} onChange={handleInputComment} value={commentText} placeholder="Write your comment here ..." disabled={disabledInput} /> <span className="btn-primary-custom" style={styles.commentBtn} onClick={() => createComment()}>Commenter</span>
                {
                    comments.map(item => {
                        return (
                            <Comment riskId={riskId} key={item.id} id={item.id} comment={item.commentText} credate={item.credate} fullname={item.fullName} creatorId={item.creatorId} replies={item.replies} getComments={getComments} />
                        )
                    })
                }
            </div>
        </>
    );
}
